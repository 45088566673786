import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { EncryptedStorage } from "../utils/encrypted-storage";
import { OrgAddressDetails } from "src/app/modules/administrator/manage-organisation/model/create-org.model";
import { currentOrg } from "../utils/current-org";
import { BroadcastModel } from "src/app/modules/message-board-old/common-structure/new-broadcast/broadcast.model";
import { CoversationModel } from "src/app/modules/message-board-old/common-structure/new-conversation/coversation.model";
import { currentUser } from "../utils/current-user";
import {
  MESSAGE_BOARD_SU_KEYS,
  MESSAGE_BOARD_SU_ACTION_MAPPING,
  MESSAGE_BOARD_ACTION_MAPPING,
  MESSAGE_BOARD_KEYS,
} from "src/app/modules/message-board-old/message-board-actions.mapping";
import { getActionMapping } from "../utils/get-action-by-key";
import { IResponseSchema } from "src/app/configs/api-config";

@Injectable()
export class MessageBoardService {
  baseUrl: string;
  tempBaseUrl: string;
  baseSubsUrl: string;
  private archiveUndo = new Subject<any>();
  userName = currentUser().userName;
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.MessageBoardAPIUrl + "/message-service/message";
    this.tempBaseUrl = environment.MessageBoardAPIUrl + "/message-service/message";
    // this.baseUrl = '../../../assets/json/temp-messages.json';
    this.baseSubsUrl = environment.apiUrl + "/subscription-service";
  }

  getPaginatedConversations(currentPage: number = 1, isArchive: 0 | 1): Observable<any> {
    const pageLimit = 20;
    return this.httpClient.get(
      `${this.tempBaseUrl}/msgConversation?limit=${pageLimit}&page=${currentPage}&isArchive=${isArchive}`
    );
  }

  getSearchedPaginatedConversations(currentPage: number = 1, searchObj, isArchive: 0 | 1): Observable<any> {
    const pageLimit = 20;
    const messageSearchReq = {
      "endDate": searchObj.toDate,
      "isArchive": isArchive,
      "limit": pageLimit,
      "messageType": searchObj.type,
      "page": currentPage,
      "searchText": searchObj.search,
      "startDate": searchObj.fromDate
    }
    return this.httpClient.post(
      `${this.tempBaseUrl}/msgConversation/search`, messageSearchReq
    );
  }

  getPaginatedInboxMessages(currentPage: number = 1, conversationId: number, pageLimit: number): Observable<any> {
    return this.httpClient.get(
      `${this.tempBaseUrl}/msgConversation/${conversationId}?limit=${pageLimit}&page=${currentPage}`
    );
  }


  getPaginatedBroadcasts(currentPage: number = 1): Observable<any> {
    const pageLimit = 20;
    return this.httpClient.get(
      `${this.tempBaseUrl}/msgBroadcaset?limit=${pageLimit}&page=${currentPage}`
    );
  }

  getSearchedPaginatedBroadcasts(currentPage: number = 1, searchObj): Observable<any> {
    const pageLimit = 20;
    const messageSearchReq = {
      "endDate": searchObj.toDate,
      "limit": pageLimit,
      "messageType": searchObj.type,
      "page": currentPage,
      "searchText": searchObj.search,
      "startDate": searchObj.fromDate
    }
    return this.httpClient.post(
      `${this.tempBaseUrl}/msgBroadcaset/search`, messageSearchReq
    );
  }

  toggleConversationArchiveInbox(conversationId: any, isArchive: 0 | 1): Observable<any> {
    return this.httpClient.put(
      `${this.tempBaseUrl}/msgConversation/setToArchive?conversationId=${conversationId}&isArchive=${isArchive}`, {}
    );
  }

  toggleConversationReadUnread(conversationId: any, isRead: 0 | 1): Observable<any> {
    return this.httpClient.put(
      `${this.tempBaseUrl}/msgConversation/setToRead?conversationId=${conversationId}&isRead=${isRead}`, {}
    );
  }

  toggleConversationPinUnPin(conversationId: any, isPinned: 0 | 1): Observable<any> {
    return this.httpClient.put(
      `${this.tempBaseUrl}/msgConversation/setToPinned?conversationId=${conversationId}&isPinned=${isPinned}`, {}
    );
  }

  toggleBroadcastReadUnreadPinnedUnPinned(brdId: any, isRead: 0 | 1, isPinned: 0 | 1): Observable<any> {
    return this.httpClient.put(
      `${this.tempBaseUrl}/msgBroadcaset/setToPinnedAndToRead?brdId=${brdId}&isPinned=${isPinned}&isRead=${isRead}`, {}
    );
  }

  getAllMessages(auditorId?: string): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}`);
  }

  getAllInboxMessages(): Observable<any> {
    return this.httpClient.get(
      // `${this.baseUrl}/msgConversation?userName=${this.userName}`
      `${this.baseUrl}/msgConversation`
    );
  }

  getAllArchiveMessages(): Observable<any> {
    return this.httpClient.get(
      // `${this.baseUrl}/msgConversation/archive?userName=${this.userName}`
      `${this.baseUrl}/msgConversation/archive`
    );
  }

  getAllBroadCastMessages(): Observable<any> {
    return this.httpClient.get(
      // `${this.baseUrl}/msgBroadcaset?userName=${this.userName}`
      `${this.baseUrl}/msgBroadcaset`
    );
  }

  getAllBroadCastMessagesTest(): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/msgBroadcaset/all`);
  }
  // /message/msgInbox?userName=shad.perry
  getUsersByOrg(orgId): Observable<any> {
    return this.httpClient.get(
      `${this.baseSubsUrl}/sub/user/${orgId}/findAllUser`
    );
  }

  getClientSupervisorAndAuditorByOrg(): Observable<any> {
    let orgId = currentOrg().organizationId;
    return this.httpClient.get(
      `${this.baseSubsUrl}/sub/user/${orgId}/findClientSupervisorAndAuditor`
    );
  }

  getAllAuditorByOrgId() {
    let orgId = currentOrg().organizationId;
    return this.httpClient.get(
      `${this.baseSubsUrl}/sub/user/${orgId}/findAllAuditor`
    );
  }

  getAllUsersByOrgId() {
    let orgId = currentOrg().organizationId;
    return this.httpClient.get(`${this.baseSubsUrl}/sub/user/${orgId}`);
  }

  getUsersAndGroupByOrg(): Observable<any> {
    let orgId = currentOrg().organizationId;
    return this.httpClient.get(
      `${this.baseSubsUrl}/sub/group/${orgId}?userId=0`
    );
  }

  getActionIds(userType: string, actionKey: string) {
    if (userType == "SU") {
      const { sysActionId, actionId } = getActionMapping(
        actionKey,
        MESSAGE_BOARD_SU_KEYS.SYS_MODULE_ID,
        MESSAGE_BOARD_SU_KEYS.SYS_SUB_MODULE_ID,
        MESSAGE_BOARD_SU_ACTION_MAPPING
      );
      return {
        sysActionId: sysActionId ? sysActionId : 0,
        actionId: actionId ? actionId : 0,
      };
    } else {
      const { sysActionId, actionId } = getActionMapping(
        actionKey,
        MESSAGE_BOARD_KEYS.SYS_MODULE_ID,
        MESSAGE_BOARD_KEYS.SYS_SUB_MODULE_ID,
        MESSAGE_BOARD_ACTION_MAPPING
      );
      return {
        sysActionId: sysActionId ? sysActionId : 0,
        actionId: actionId ? actionId : 0,
      };
    }
  }

  addNewBroadCast(payload: BroadcastModel): Observable<any> {
    const user = currentUser();
    const userType = user.sysRoleId == 310 ? "SU" : "Auditor";
    const { sysActionId, actionId } = this.getActionIds(
      userType,
      "broadcast_message_save"
    );
    return this.httpClient.post(
      `${this.tempBaseUrl}/msgBroadcaset?sysActionId=${sysActionId}&actionId=${actionId}`,
      payload
    );
  }

  addNewConversation(payload: CoversationModel): Observable<any> {
    const user = currentUser();
    const userType = user.sysRoleId == 310 ? "SU" : "Auditor";
    const { sysActionId, actionId } = this.getActionIds(
      userType,
      "add_message_save"
    );
    return this.httpClient.post(
      `${this.tempBaseUrl}/msgConversation?sysActionId=${sysActionId}&actionId=${actionId}`,
      payload
    );
  }

  getAllUserAccount(): Observable<any> {
    return this.httpClient.get(`${this.tempBaseUrl}/msgConversation/userAccount`);
  }

  getAllPredictionCode(): Observable<any> {
    return this.httpClient.get(`${this.tempBaseUrl}/msgConversation/userPredCode`);
  }
  updateIsReadConversation(
    conversationId: any,
    isRead: number
  ): Observable<any> {
    return this.httpClient.put(
      `${this.baseUrl}/msgConversation/setToRead?conversationId=${conversationId}&isRead=${isRead}`,
      []
    );
  }
  updateIsReadBroadcast(
    brdId: any,
    isRead: number
  ): Observable<any> {
    return this.httpClient.put(
      `${this.baseUrl}/msgBroadcaset/setToRead?brdId=${brdId}&isRead=${isRead}`,
      []
    );
  }
  updateIsReadIsPinnedBroadCast(
    brdId: any,
    isPinned: any,
    isRead: number
  ): Observable<any> {
    return this.httpClient.put(
      `${this.baseUrl}/msgBroadcaset/setToPinnedAndToRead?brdId=${brdId}&isPinned=${isPinned}&isRead=${isRead}`,
      []
    );
  }

  // "http://103.102.234.190:9700/message-service/message/msgBroadcaset/setToPinnedAndToRead?brdId=10064&isPinned=1&isRead=1"

  updateIsArchiveConversation(
    conversationId: any,
    userName: any,
    isArchive: number
  ): Observable<any> {
    const user = currentUser();
    const userType = user.sysRoleId == 310 ? "SU" : "Auditor";
    const { sysActionId, actionId } = this.getActionIds(
      userType,
      "message_archive_move"
    );
    return this.httpClient.put(
      `${this.baseUrl}/msgConversation/setToArchive?conversationId=${conversationId}&userName=${userName}&isArchive=${isArchive}&sysActionId=${sysActionId}&actionId=${actionId}`,
      []
    );
    // /message-service/message/msgConversation/setToArchive?conversationId={conversationId}&isArchive={archiveFlag}
  }

  //http://103.102.234.190:9700/message-service/message/msgBroadcaset/setToPinned?brdId=10060&brdRecipientId=100111&isPinned=1
  // updateIsPinned(brdId: any, brdRecipientId: any, isPinned: any) {
  //   return this.httpClient.put(
  //     `${this.baseUrl}/msgBroadcaset/setToPinned?brdId=${brdId}&brdRecipientId=${brdRecipientId}&isPinned=${isPinned}`,
  //     []
  //   );
  // }

  refreshAfterArchivedUndo(): Observable<any> {
    return this.archiveUndo.asObservable();
  }

  setArchiveUndo() {
    this.archiveUndo.next();
  }

  // updateIsReadBroadcast(conversationId: any, brdRecipientId: any, isRead: number): Observable<any>  {
  //   return this.httpClient.put(
  //     `${this.baseUrl}/msgConversation/setToRead?conversationId=${conversationId}&brdRecipientId=${brdRecipientId}&isRead=${isRead}`,[]
  //   );
  // }

  getAllNotificationAlerts() {
    return this.httpClient.get<IResponseSchema>(
      `${this.baseUrl}/msgAlert`
    );
  }

  getAllNotificationMsgs() {
    return this.httpClient.get<IResponseSchema>(
      `${this.baseUrl}/msgBellIcon`
    );
  }

  setSingleAlertAsRead(alertId) {
    return this.httpClient.put(
      `${this.baseUrl}/msgAlert?alertId=${alertId}&isRead=${1}`,
      []
    );
  }

  setAllAlertAsRead() {
    return this.httpClient.put(
      `${this.baseUrl}/msgAlert/ALL?isRead=${1}`,
      []
    );
  }

  setAllConversationAsRead() {
    return this.httpClient.put(
      `${this.baseUrl}/msgConversation/setToReadAll?isRead=${1}`,
      []
    );
  }

  setAllBroadcastAsRead() {
    return this.httpClient.put(
      `${this.baseUrl}/msgBroadcaset/setToReadAll?isRead=${1}`,
      []
    );
  }

  

}
