import { Injectable } from "@angular/core";
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { StompHeaders } from '@stomp/stompjs';
import { Client, StompConfig } from '@stomp/stompjs';
import { Subscription } from "rxjs";
import { currentUser } from "../utils/current-user";
import { EncryptedStorage } from "../utils/encrypted-storage";
import { GlobalConfig } from "src/app/configs/global-config";
import * as moment from "moment";
import { LoginService } from "../_http/login.service";
import { AppConfig } from "../_global/environment-config.service";
import { environment } from "src/environments/environment";

console.log(environment.MessageBoardAPIUrl);
const baseConnection: string = `${environment.MessageBoardAPIUrl}/message-service/sockjs`;

// endpoints

const messageSendEndPoint: string = `/app/msgConversation`;

const messageRecieverEndPoint = `/user/queue/msgConversation`;

const notificationRecieverEndPoint = `/user/queue/msgAlert`;

const broadcastsSendingEndPoint = `/app/msgBroadcast`;

const broadcastsRecieverEndPoint = `/user/queue/msgBroadcast`;
// (param: string) => param ? `/secured/user/queue/specific-user${param}` : `/topic/msgConversation`;

@Injectable()
export class MessageBoardSocketService {

    // token = new EncryptedStorage().findItemFromAllStorage(new GlobalConfig().authTokenLSName);
    private token: string = ""
    private userName: string = "";
    stompClient: Stomp.Client;
    private stompConfig: StompConfig | undefined;
    private maxConnectAttempt = 3;
    private totalConnectAttempt = 0;
    public isConnecting = true;
    public isConnectionFailed = false;
    private subsRecords = {};
    
    private baseSetup() {
        this.token = new EncryptedStorage().findItemFromAllStorage(new GlobalConfig()?.authTokenLSName);
        this.userName = currentUser().userName;
        const headers1 = {
            Authorization: this.token
        };
        const sockJsOptions = {
            transports: ['websocket'],
            headers: {
                Authorization: this.token,
                // withCredentials: true
            }
        };
        let ws = new SockJS(baseConnection, null, sockJsOptions);
        this.stompClient = Stomp.over(ws);
    }

    constructor(private authService: LoginService, private appConfig: AppConfig) {
        // this.baseSetup();
    }

    reconnectOnTokenExpire() {
        return new Promise<void>((resolve, reject) => {
            if(this.stompClient?.connected) {
                const subs = this.stompClient?.subscriptions ? {...this.stompClient.subscriptions} : null
                this.stompClient.disconnect(() => {
                    this.connectUser(() => {
                        if(subs) {
                            Object.keys(subs).map((subId, i) => {
                                if(this.subsRecords[subId]) {
                                    this.stompClient.subscribe(this.subsRecords[subId], subs[subId]);
                                }
                                if(i == (Object.keys(subs).length-1)) {
                                    // when all elements subscribed
                                    resolve();
                                }
                            });
                        } else {
                            // if no subscriptions available
                            resolve();
                        }
                    });
                })    
            } else {
                resolve();
            }
        })
    }

    parseJwt = (token) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
    };

    connectUser(callback?) {
        this.baseSetup();
        return new Promise<void>((resolve, reject) => {
            const _this = this;
            this.totalConnectAttempt += 1;
            _this.stompClient.connect({Authorization: this.token}, (frame: any) => {
                this.totalConnectAttempt = 0;
                this.isConnecting = false;
                this.isConnectionFailed = false;
                if(callback) callback();
                resolve();
            }, (err) => this.errorCallBack(err));
        })
    }

    // on error, schedule a reconnection attempt
    private errorCallBack(error: Stomp.Frame | string) {
        console.log("errorCallBack -> " + error);
        this.isConnectionFailed = true;
        if(this.totalConnectAttempt <= this.maxConnectAttempt) {
            if(error.toString().includes("401")) {
                this.refreshToken();
            }  else {
                this.connectUser();
            }
        } else {
            console.warn("Reached Max Connection Attempt");
        }
    }

    refreshToken() {
        return new Promise<void>((resolve, reject) => {
            const refreshToken = new EncryptedStorage().findItemFromAllStorage(
                new GlobalConfig().authRefreshTokenLSName
            );
            if (refreshToken) {
                this.authService.generateNewAuthTokenUsingRefreshToken(this.appConfig.baseUrl,refreshToken).subscribe(
                    response => {
                        if(!response.error) {
                            // Set New Token
                            new EncryptedStorage().setItem(
                                new GlobalConfig().authTokenLSName,
                                response.data.tokenType + response.data.accessToken,
                                !new EncryptedStorage().IsLocalStorage)
                                // Set New Refresh Token
                                new EncryptedStorage().setItem(
                                new GlobalConfig().authRefreshTokenLSName,
                                response.data.refreshToken,
                                !new EncryptedStorage().IsLocalStorage)
                            try {
                                this.reconnectOnTokenExpire().then(() => {
                                    resolve();
                                });
                            } catch (e) {
                                // failed to reconnect
                                reject()
                            }
                        } else {
                            reject()
                        }
                    }
                );
            }
        })
    }


    /**
     * Message reciever from server via web socket
     * @param {callback} callback callback method with Message Params
     * @returns {*} subscription object to unsubscribe after destroy 
     */
    userMessageListener(callback: (message: Stomp.Message) => any) {
        const subscription = this.stompClient.subscribe(messageRecieverEndPoint, callback);
        this.subsRecords[subscription.id] = messageRecieverEndPoint;
        return subscription;
    }

    /**
     * Send Message to Room via web socket
     * @param {*} newMessageObject callback method with Message Params
     */
    sendNewMessageToRoom(newMessageObject) {
        const jwt = this.parseJwt(this.token);
        if(jwt && jwt?.exp) {
            const secondsLeft = moment.duration(moment.unix(jwt?.exp).diff(moment())).as('seconds') || 0;
            console.log(secondsLeft);
            if(secondsLeft < 10) {
                this.refreshToken().then(() => {
                    this.stompClient.send(messageSendEndPoint, {Authorization: this.token}, JSON.stringify(newMessageObject));
                })
            } else {
                this.stompClient.send(messageSendEndPoint, {Authorization: this.token}, JSON.stringify(newMessageObject));
            }
        } else {
            this.stompClient.send(messageSendEndPoint, {Authorization: this.token}, JSON.stringify(newMessageObject));
        }
    }

    /**
     * Broadcast reciever from server via web socket
     * @param {callback} callback callback method with Message Params
     * @returns {*} subscription object to unsubscribe after destroy 
     */
    userBroadcastsListener(callback: (message: Stomp.Message) => any) {
        const subscription = this.stompClient.subscribe(broadcastsRecieverEndPoint, callback)
        this.subsRecords[subscription.id] = broadcastsRecieverEndPoint;
        return subscription;
        
    }

    /**
     * Notification reciever from server via web socket
     * @param {callback} callback callback method with Message Params
     * @returns {*} subscription object to unsubscribe after destroy 
     */
    userNotificationListener(callback: (message: Stomp.Message) => any) {
        const subscription = this.stompClient.subscribe(notificationRecieverEndPoint, callback);
        this.subsRecords[subscription.id] = notificationRecieverEndPoint;
        return subscription;
    }

}