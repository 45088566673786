import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'messageSummary'
})
export class NotificationMessageSummaryPipe implements PipeTransform {

  transform(htmlContent: string): unknown {
    const element = document.createElement('span');
    element.innerHTML = htmlContent;
    const text = element.innerText;
    element.remove();
    return text;
  }

}
