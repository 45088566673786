import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { dateTimeToUserTzByFormat } from "src/app/shared/components/rm-grid/src/grid-utils/date-formatter";
import { currentUser } from "src/app/shared/utils/current-user";

@Pipe({
  name: "dateTimeFormatter",
})
export class DateTimeFormatterPipe implements PipeTransform {
  transform(value: any, format: string): any {
    return dateTimeToUserTzByFormat(value, format)
  }
}
