import { Component, OnInit } from '@angular/core';
import { GlobalConfig } from './configs/global-config';
import { EncryptedStorage } from './shared/utils/encrypted-storage';
import { ThemeService } from './shared/_global/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ThemeService]
})
export class AppComponent implements OnInit {
  
  constructor(private themeService: ThemeService) {}
  
  ngOnInit() {
    this.themeService.getThemes().subscribe(
      response => {
        let currentThemeId = getComputedStyle(document.documentElement).getPropertyValue("--global-theme-id");
        if(typeof response == "string")
        response = JSON.parse(response);
        const lsInfo = new EncryptedStorage().getItem(new GlobalConfig().userAllDetailsLSName)
        if(lsInfo) {
          currentThemeId = JSON.parse(lsInfo)['colourThemeId']
        }
        this.themeService.changeTheme(currentThemeId, document, response.find(val => val["theme-id"] == currentThemeId))
      }
    );
  }





}
