import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MaterialModule } from "../../shared-modules/material.module";
import { RMHeaderComponent } from "./rm-header.component";
import { RMIconModule } from "../rm-icon/package.module";
import { RouterModule } from "@angular/router";
import { SubHeaderTitlePipe } from './sub-header-title.pipe';
import { SubHeaderBreadCrumbPipe } from "./sub-header-breadcrumb.pipe";

@NgModule({
  exports: [RMHeaderComponent],
  declarations: [RMHeaderComponent, SubHeaderTitlePipe, SubHeaderBreadCrumbPipe],
  imports: [
    BrowserModule,
    RouterModule,
    MaterialModule,
    RMIconModule,
  ],
})
export class RMHeaderModule {}
