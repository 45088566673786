import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, filter, catchError, retryWhen, tap, finalize, switchMap, take } from 'rxjs/operators';
import { GlobalConfig } from 'src/app/configs/global-config';
import { EncryptedStorage } from '../utils/encrypted-storage';
import { Router } from '@angular/router';
import { RmToastyService } from '../components/rm-toasty/rm-toasty/rm-toasty.service';
import { LoginService } from '../_http/login.service';
import { AppConfig } from '../_global/environment-config.service';
import { MessageBoardSocketService } from '../_ws/message-board-scoket.service';


@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private router: Router, 
    private toastyService: RmToastyService, 
    private socketService: MessageBoardSocketService,
    private authService: LoginService,
    private appConfig: AppConfig) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let time = 0;
    const Authorization = new EncryptedStorage().findItemFromAllStorage(new GlobalConfig().authTokenLSName);
    const ServerKey = new EncryptedStorage().findItemFromAllStorage(new GlobalConfig().serverKeyLSName) || "X-Server-Id";
    const ServerId = new EncryptedStorage().findItemFromAllStorage(new GlobalConfig().serverIdLSName) || "0";
    if(Authorization) {
      let timer = setInterval(()=> {
        time += 1;
        if(time >= 5) {
          this.toastyService.clear()
          this.toastyService.info("Please Wait, Server is taking time more than expected", null, {positionClass: 'toast-bottom-right', closeButton: true});
          clearInterval(timer)
        }
      },1000)
      httpRequest = httpRequest.clone({ setHeaders: { Authorization, [ServerKey]: ServerId } });
      return next.handle(httpRequest).pipe(
        finalize(
          () => {
            clearInterval(timer)
          }
        ),
        catchError((err) => {
          clearInterval(timer)
          // 
          if (err instanceof HttpErrorResponse) {
              if (err.status === 401 || err.status === 403) {
                return this.handleUnauthorizedUserError(httpRequest, next, ServerKey, ServerId)
              } else if(err.status === 504) {
                this.throwGatewayError();
              } else if(err.status === 406) {
                return this.notAcceptableErrorHandling(err);
              }
          }
          // return new Observable<HttpEvent<any>>();
          return throwError(err);
        })
    );
      // return next.handle(httpRequest.clone({ setHeaders: { Authorization } }));
    } else {
      // httpRequest = httpRequest.clone({ responseType: "text" });
      return next.handle(httpRequest).pipe(
        catchError((err: any) => {
          // 
          if (err instanceof HttpErrorResponse) {
              if (err.status === 401 || err.status === 403) {
                this.timeoutSessionLogoutUser();
              } else if(err.status === 504) {
                this.throwGatewayError();
              } else if(err.status === 406) {
                return this.notAcceptableErrorHandling(err);
              }
          }
          return throwError(err);
          // return new Observable<HttpEvent<any>>();
        })
      );
    }
  }

  notAcceptableErrorHandling(err: HttpErrorResponse) {
    const messagesData = err.error?.data;
    if(messagesData) {
      Object.keys(messagesData).forEach(key => {
        const mes = `${key}: ${messagesData[key]}`;
        if(mes.length > 15) {
          this.toastyService.error(mes, null, { disableTimeOut: true, closeButton: true, tapToDismiss: true});
        } else {
          this.toastyService.error(mes);
        }
      });
    } else {
      this.toastyService.error(err.error?.message || "Not Acceptable");
    }
    const response = new HttpResponse({
      body: {
        ...err.error,
        statusCode: 406
      },
      status: 200,
      statusText: 'OK',
    });
    return new Observable<HttpEvent<any>>((observer) => observer.next(response));
  }

  handleUnauthorizedUserError(request: HttpRequest<any>, next: HttpHandler, serverKey: string, serverId): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = new EncryptedStorage().findItemFromAllStorage(
        new GlobalConfig().authRefreshTokenLSName
      );
      if (refreshToken)
        return this.authService.generateNewAuthTokenUsingRefreshToken(this.appConfig.baseUrl,refreshToken).pipe(
          switchMap((response: any) => {
            if(!response.error) {
              this.isRefreshing = false;
              // Set New Token
              new EncryptedStorage().setItem(
                new GlobalConfig().authTokenLSName,
                response.data.tokenType + response.data.accessToken,
                !new EncryptedStorage().IsLocalStorage)
                // Set New Refresh Token
              new EncryptedStorage().setItem(
                new GlobalConfig().authRefreshTokenLSName,
                response.data.refreshToken,
                !new EncryptedStorage().IsLocalStorage)
              this.refreshTokenSubject.next(response.data.tokenType + response.data.accessToken);
              try {
                this.socketService.reconnectOnTokenExpire();
              } catch (e) {
                // failed to reconnect
              }
              return next.handle(this.addTokenHeader(request, response.data.tokenType + response.data.accessToken, serverKey, serverId));
            } else {
              this.timeoutSessionLogoutUser();
            }
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.timeoutSessionLogoutUser();
            return throwError(err);
          })
        );
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token: string) => next.handle(this.addTokenHeader(request, token, serverKey, serverId)))
    );
  }

  timeoutSessionLogoutUser() {
    this.toastyService.error(
      'Your session is timeout, please login again.',
      null,
      { positionClass: 'toast-bottom-right' }
    );
    new EncryptedStorage().clearAll();
    this.router.navigate([new GlobalConfig().loginRoute]);
  }

  private addTokenHeader(request: HttpRequest<any>, Authorization: string, serverKey: string, serverId: string) {
    return request.clone({ setHeaders: { Authorization, [serverKey]: serverId } });;
  }

  throwGatewayError() {
    this.toastyService.error('API Gateway Error', null, {
      positionClass: 'toast-bottom-center',
    });
  }
}