import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./shared/shared-modules/material.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EmptyLayoutComponent } from "./core/layouts/EmptyLayout/empty-layout.component";
import { NavigationLayoutComponent } from "./core/layouts/NavigationLayout/navigation.component";
import { AppHeaderModule } from "./core/header/header.module";
import { RMNavModule } from "./shared/components/rm-nav/package.module";
import { Toast } from "./shared/components/rm-toasty/rm-toasty/rm-toasty.component";
import { AuthLayoutComponent } from "./core/layouts/AuthLayout/auth-layout.component";
import { RMDrawerPanelModule } from "./shared/components/rm-drawer-panel/src/package.module";
import { RMUserDrawerModule } from "./shared/components/rm-user-drawer/package.module";
import { AuthTokenInterceptor } from "./shared/interceptors/auth.interceptor";
import { AuthRouteGuard } from "./shared/guard/auth-route.guard";
import { LoginRouteGuard } from "./shared/guard/login-route.guard";
import { UserProfileService } from "./shared/_http/user-profile.service";
import { RMToastyModule } from "./shared/components/rm-toasty/rm-toasty/package.module";
import { RmToastyService } from "./shared/components/rm-toasty/rm-toasty/rm-toasty.service";
import "ag-grid-enterprise";

import { appInitializer } from "./_helpers/app.initializer";
import { PageNotFoundComponent } from "./core/blanks/page-not-found/page-not-found.component";
import { RMLoaderModule } from "./shared/components/rm-loader/package.module";
import {
  AppConfig,
  initConfig,
} from "./shared/_global/environment-config.service";
import { LoginService } from "./shared/_http/login.service";
import { MessageBoardSocketService } from "./shared/_ws/message-board-scoket.service";
import { RMNotificationPanelModule } from "./shared/components/rm-notification-panel/package.module";
@NgModule({
    declarations: [
        AppComponent,
        EmptyLayoutComponent,
        NavigationLayoutComponent,
        AuthLayoutComponent,
        PageNotFoundComponent,
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthRouteGuard,
        LoginRouteGuard,
        UserProfileService,
        RmToastyService,
        LoginService,
        MessageBoardSocketService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfig],
            multi: true,
        },
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AppHeaderModule,
        RMNavModule,
        RMLoaderModule,
        // Drawer Panel
        RMNotificationPanelModule,
        RMDrawerPanelModule,
        RMUserDrawerModule,
        RMToastyModule.forRoot(),
    ]
})
export class AppModule {}
