export class GlobalConfig {
    themeColors = []
    // LocalStorage Name
    bookmarkLSName = "_rm-b";
    authTokenLSName = "_rm-at";
    authRefreshTokenLSName = "_rm-rat";
    serverKeyLSName = "_rm-sidk";
    serverIdLSName = "_rm-sidn";
    colourThemeLSName = "_rm-ct";
    availableThemesLSName = "rm-oat"
    generalSettingLSName = "_rm-gs"
    userAllDetailsLSName = "_rm-sad";
    userOrgTypeLSName = "_rm-uot";
    userModulesSubmodulesLSName = "_rm-snd";
    userDetailsLSName = "_rm-sud";
    userNameLSName = "_rm-sund";
    userIdLSName = "_rm-sid";
    userLogginIdLSName = "_rm-slid";
    organisationIdLSName = "_rm-sod";
    organisationTypeLSName = "_rm-ort";
    usersListLSName = "_rm-usrlst";
    roleIdLSName = "_rm-srd";
    responseCodesClientMaster = "_rm-rc";
    accountsList = "_rm-aclp"
    accountTypesList = "_rm-actyplp" // with overall
    accountTypeList = "_rm-actbsyplp" // without overall
    subscriptionServiceTypesList = "_rm-sstyplp"
    tokenTimeToReset = "_rm-tttr"
    // important routes
    loginRoute = "/auth/login";
    profileRoute = "/my-account";
    dashboardRoute = "/dashboard";
}