import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./core/blanks/page-not-found/page-not-found.component";
import { AuthLayoutComponent } from "./core/layouts/AuthLayout/auth-layout.component";
import { NavigationLayoutComponent } from "./core/layouts/NavigationLayout/navigation.component";
import { AuthRouteGuard } from "./shared/guard/auth-route.guard";
import { LoginRouteGuard } from "./shared/guard/login-route.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    canActivate: [LoginRouteGuard],
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "dashboard",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "my-account",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/my-account/my-account.module").then(
        (m) => m.MyAccountModule
      ),
  },
  {
    path: "wip",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "manage-master",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/administrator/manage-master/manage-master.module").then(
        (m) => m.ManageMasterModule
      ),
  },
  {
    path: "manage-server",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/administrator/manage-server/manage-server.module").then(
        (m) => m.ManageServerModule
      ),
  },
  {
    path: "manage-feature",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import(
        "./modules/administrator/manage-feature/manage-feature.module"
      ).then((m) => m.ManageFeatureModule),
  },
  {
    path: "manage-subscription",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import(
        "./modules/administrator/manage-subscription/manage-subscription.module"
      ).then((m) => m.ManageSubscriptionModule),
  },
  {
    path: "manage-roles",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/administrator/manage-role/manage-role.module").then(
        (m) => m.ManageRoleModule
      ),
  },
  {
    path: "manage-org",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import(
        "./modules/administrator/manage-organisation/manage-organisation.module"
      ).then((m) => m.ManageOrganisationModule),
  },
  {
    path: "manage-user-and-group",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/administrator/manage-user/manage-user.module").then(
        (m) => m.ManageUserModule
      ),
  },
  {
    path: "notification",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/notifications/notifications.module").then(
        (m) => m.NotificationsModule
      ),
  },
  {
    path: "account-review",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/account-review/account-review.module").then(
        (m) => m.AccountReviewModule
      ),
  },
  {
    path: "workspace",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/workspace/workspace.module").then(
        (m) => m.WorkspaceModule
      ),
  },
  {
    path: "message-board",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/message-board/message-board.module").then(
        (m) => m.MessageBoardModule
      ),
  },
  {
    path: "supervision-tools",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/supervision-tools/supervision-tools.module").then(
        (m) => m.SupervisionToolsModule
      ),
  },
  {
    path: "client-scoring-status",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import(
        "./modules/client-scoring-status/client-scoring-status.module"
      ).then((m) => m.ClientScoringStatusModule),
  },
  {
    path: "client-scoring-metrics",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import(
        "./modules/client-scoring-metrics/client-scoring-metrics.module"
      ).then((m) => m.ClientScoringMetricsModule),
  },
  {
    path: "report",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/report/report.module").then((m) => m.ReportModule),
  },
  {
    path: "configurations",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/configurations/configurations.module").then(
        (m) => m.ConfigurationsModule
      ),
  },
  {
    path: "dataflow",
    component: NavigationLayoutComponent,
    canActivate: [AuthRouteGuard],
    loadChildren: () =>
      import("./modules/dataflow/dataflow.module").then((m) => m.DataflowModule),
  },
  // Unmatched Route
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
