import { EncryptedStorage } from "../shared/utils/encrypted-storage";
import { GlobalConfig } from "./global-config";

// [moduleId, subModuleId, actionId?]
export const FeatureModuleTitleLabelingIds = {
    "SP": {
        "SpDashboard": [
            100,
            101,
            10101
        ],
        "SpDashboardHighlights": [
            100,
            101,
            10102
        ],
        "SpDashboardEditRequest": [
            100,
            101,
            10103
        ],
        "SpManageSystemMaster": [
            150,
            151,
            15101
        ],
        "SpManageSystemMasterEditSystemMaster": [
            150,
            151,
            15102
        ],
        "SpManageSystemMasterNewItem": [
            150,
            151,
            15103
        ],
        "SpManageSystemMasterEditItem": [
            150,
            151,
            15104
        ],
        "SpManageSystemMasterDeleteItem": [
            150,
            151,
            15105
        ],
        "SpManageSystemMasterBulkUpload": [
            150,
            151,
            15106
        ],
        "SpManageSystemMasterReorderMaster": [
            150,
            151,
            15107
        ],
        "SpManageSubscription": [
            150,
            152,
            15201
        ],
        "SpManageSubscriptionNewSubscriptionGroup": [
            150,
            152,
            15202
        ],
        "SpManageSubscriptionEditSubscriptionGroup": [
            150,
            152,
            15203
        ],
        "SpManageSubscriptionDeleteSubscriptionGroup": [
            150,
            152,
            15204
        ],
        "SpManageSubscriptionNewService": [
            150,
            152,
            15205
        ],
        "SpManageSubscriptionEditService": [
            150,
            152,
            15206
        ],
        "SpManageSubscriptionDeleteService": [
            150,
            152,
            15207
        ],
        "SpManageSubscriptionNewPackage": [
            150,
            152,
            15208
        ],
        "SpManageSubscriptionViewPackage": [
            150,
            152,
            15209
        ],
        "SpManageSubscriptionEditPackage": [
            150,
            152,
            15210
        ],
        "SpManageSubscriptionDeletePackage": [
            150,
            152,
            15211
        ],
        "SpManageSubscriptionManageServices": [
            150,
            152,
            15212
        ],
        "SpManageSubscriptionMapNewOrganization": [
            150,
            152,
            15213
        ],
        "SpManageSubscriptionComparePakcages": [
            150,
            152,
            15214
        ],
        "SpManageSubscriptionMappedOrganization": [
            150,
            152,
            15215
        ],
        "SpManageFeatureModuleManageFeature": [
            150,
            153,
            15301
        ],
        "SpManageFeatureModuleNewModule": [
            150,
            153,
            15302
        ],
        "SpManageFeatureModuleEditModule": [
            150,
            153,
            15303
        ],
        "SpManageFeatureModuleDeleteModule": [
            150,
            153,
            15304
        ],
        "SpManageFeatureModuleNewSubModule": [
            150,
            153,
            15305
        ],
        "SpManageFeatureModuleEditSubModule": [
            150,
            153,
            15306
        ],
        "SpManageFeatureModuleDeleteSubModule": [
            150,
            153,
            15307
        ],
        "SpManageFeatureModuleNewAction": [
            150,
            153,
            15308
        ],
        "SpManageFeatureModuleEditAction": [
            150,
            153,
            15309
        ],
        "SpManageFeatureModuleDeleteAction": [
            150,
            153,
            15310
        ],
        "SpManageFeatureModuleBulkUpload": [
            150,
            153,
            15311
        ],
        "SpManageRoles": [
            150,
            154,
            15401
        ],
        "SpManageRolesNewRole": [
            150,
            154,
            15402
        ],
        "SpManageRolesViewRole": [
            150,
            154,
            15403
        ],
        "SpManageRolesEditRole": [
            150,
            154,
            15404
        ],
        "SpManageRolesDeleteRole": [
            150,
            154,
            15405
        ],
        "SpManageRolesMapNewOrganization": [
            150,
            154,
            15406
        ],
        "SpManageRolesUpdateOrder": [
            150,
            154,
            15407
        ],
        "SpManageRolesEditStatus": [
            150,
            154,
            15408
        ],
        "SpManageOrganization": [
            150,
            155,
            15501
        ],
        "SpManageOrganizationNewOrganization": [
            150,
            155,
            15502
        ],
        "SpManageOrganizationOrganizationSummary": [
            150,
            155,
            15503
        ],
        "SpManageOrganizationEditOrganization": [
            150,
            155,
            15504
        ],
        "SpManageOrganizationDeleteOrganization": [
            150,
            155,
            15505
        ],
        "SpManageOrganizationAddAdminUser": [
            150,
            155,
            15506
        ],
        "SpManageOrganizationEditStatus": [
            150,
            155,
            15507
        ],
        "SpManageOrganizationNewTenantConfiguration": [
            150,
            155,
            15508
        ],
        "SpManageOrganizationEditTenantConfiguration": [
            150,
            155,
            15509
        ],
        "SpManageOrganizationPublishTenantConfiguration": [
            150,
            155,
            15510
        ],
        "SpManageOrganizationTestTenantConfiguration": [
            150,
            155,
            15511
        ],
        "SpManageUser&GroupManageUsersandGroups": [
            150,
            156,
            15601
        ],
        "SpManageUser&GroupNewUser": [
            150,
            156,
            15602
        ],
        "SpManageUser&GroupViewUser": [
            150,
            156,
            15603
        ],
        "SpManageUser&GroupEditUser": [
            150,
            156,
            15604
        ],
        "SpManageUser&GroupDeleteUser": [
            150,
            156,
            15605
        ],
        "SpManageUser&GroupResetPassword": [
            150,
            156,
            15606
        ],
        "SpManageUser&GroupNewGroup": [
            150,
            156,
            15607
        ],
        "SpManageUser&GroupViewGroup": [
            150,
            156,
            15608
        ],
        "SpManageUser&GroupEditGroup": [
            150,
            156,
            15609
        ],
        "SpManageUser&GroupDeleteGroup": [
            150,
            156,
            15610
        ],
        "SpManageUser&GroupBulkUserDelete": [
            150,
            156,
            15611
        ],
        "SpManageUser&GroupBulkUserUpdate": [
            150,
            156,
            15612
        ],
        "SpManageUser&GroupEditUserStatus": [
            150,
            156,
            15613
        ],
        "SpManageUser&GroupBulkGroupDelete": [
            150,
            156,
            15614
        ],
        "SpManageUser&GroupBulkGroupUpdate": [
            150,
            156,
            15615
        ],
        "SpManageUser&GroupEditGroupStatus": [
            150,
            156,
            15616
        ],
        "SpManageUser&GroupSendEmail": [
            150,
            156,
            15617
        ],
        "SpManageUser&GroupGroupUsers": [
            150,
            156,
            15618
        ],
        "SpManageServer": [
            150,
            169,
            16901
        ],
        "SpManageServerNewServer": [
            150,
            169,
            16902
        ],
        "SpManageServerViewServer": [
            150,
            169,
            16903
        ],
        "SpManageServerEditServer": [
            150,
            169,
            16904
        ],
        "SpManageServerTestConnection": [
            150,
            169,
            16905
        ],
        "SpManageServerEditTenantStatus": [
            150,
            169,
            16906
        ],
        "SpLiveScoringTracker": [
            400,
            401,
            40101
        ],
        "SpScoringMetrics": [
            400,
            402,
            40201
        ],
        "SpSetupReportListReportConfiguration": [
            550,
            551,
            55101
        ],
        "SpSetupReportListEditStatus": [
            550,
            551,
            55102
        ],
        "SpSetupReportListViewReport": [
            550,
            551,
            55103
        ],
        "SpSetupWorkflowFilterManageWorkflow": [
            550,
            552,
            55201
        ],
        "SpSetupWorkflowFilterNewWorkflow": [
            550,
            552,
            55202
        ],
        "SpSetupWorkflowFilterEditWorkflow": [
            550,
            552,
            55203
        ],
        "SpSetupWorkflowFilterDeleteWorkflow": [
            550,
            552,
            55204
        ],
        "SpSetupWorkflowFilterEditStatus": [
            550,
            552,
            55205
        ],
        "SpManageSystemSettingsEditEmailSettings": [
            550,
            564,
            56401
        ],
        "SpManageSystemSettingsEditSystemFlags": [
            550,
            564,
            56402
        ],
        "SpManageSystemSettings": [
            550,
            564,
            56403
        ],
        "SpManageSystemSettingsTestConnection": [
            550,
            564,
            56404
        ],
        "SpManageEventManageNotifications": [
            600,
            601,
            60101
        ],
        "SpManageEventNewEvent": [
            600,
            601,
            60102
        ],
        "SpManageEventEditEvent": [
            600,
            601,
            60103
        ],
        "SpManageEventDeleteEvent": [
            600,
            601,
            60104
        ],
        "SpManageEventEditStatus": [
            600,
            601,
            60105
        ],
        "SpManageEventViewEvent": [
            600,
            601,
            60106
        ],
        "SpManageEventViewTemplate": [
            600,
            601,
            60107
        ],
        "SpManageTemplateManageTemplates": [
            600,
            602,
            60201
        ],
        "SpManageTemplateNewVariable": [
            600,
            602,
            60202
        ],
        "SpManageTemplateEditVariable": [
            600,
            602,
            60203
        ],
        "SpManageTemplateDeleteVariable": [
            600,
            602,
            60204
        ],
        "SpManageTemplateNewTemplate": [
            600,
            602,
            60205
        ],
        "SpManageTemplateEditTemplate": [
            600,
            602,
            60206
        ],
        "SpManageTemplateDeleteTemplate": [
            600,
            602,
            60207
        ],
        "SpManageTemplateMappedTemplates": [
            600,
            602,
            60208
        ],
        "SpManageTemplateMappedVariables": [
            600,
            602,
            60209
        ],
        "SpManageTemplateEditStatus": [
            600,
            602,
            60210
        ],
        "SpManageTemplateViewTemplate": [
            600,
            602,
            60211
        ],
        "SpManageTemplateCloneTemplate": [
            600,
            602,
            60212
        ],
        "SpManageTemplateManageVariables": [
            600,
            602,
            60213
        ],
        "SpManageTemplateRestoreSystemDefault": [
            600,
            602,
            60214
        ],
        "SpUserProfileProfileSummary": [
            650,
            651,
            65101
        ],
        "SpUserProfileEditProfile": [
            650,
            651,
            65102
        ],
        "SpUserProfileSecurityCodeChange": [
            650,
            651,
            65103
        ],
        "SpUserProfileChangePassword": [
            650,
            651,
            65104
        ],
        "SpUserProfileMyAccount": [
            650,
            651,
            65105
        ],
        "SpLoginPageForgetPassword": [
            650,
            652,
            65201
        ],
        "SpLoginPageSecurityCodeChange": [
            650,
            652,
            65202
        ],
        "SpLoginPageChangePassword": [
            650,
            652,
            65203
        ],
        "SpClientPerformance": [
            750,
            751,
            75101
        ],
        "SpServiceManagement": [
            750,
            752,
            75201
        ],
        "SpServerMonitoring": [
            800,
            801,
            80101
        ],
        "SpApplicationMonitoring": [
            800,
            802,
            80201
        ]
    },
    "SR": {
        "SrDashboard": [
            100,
            103,
            10301
        ],
        "SrDashboardHighlights": [
            100,
            103,
            10302
        ],
        "SrDashboardNewRequest": [
            100,
            103,
            10303
        ],
        "SrDashboardViewRequest": [
            100,
            103,
            10304
        ],
        "SrDashboardEditRequest": [
            100,
            103,
            10305
        ],
        "SrDashboardNotifyOverdueInvoice": [
            100,
            103,
            10306
        ],
        "SrDashboardNotifyExpiringSubscription": [
            100,
            103,
            10307
        ],
        "SrManageSubscription": [
            150,
            159,
            15901
        ],
        "SrManageSubscriptionNewCustomPackage": [
            150,
            159,
            15902
        ],
        "SrManageSubscriptionEditCustomPackage": [
            150,
            159,
            15903
        ],
        "SrManageSubscriptionDeleteCustomPackage": [
            150,
            159,
            15904
        ],
        "SrManageSubscriptionMapNewOrganization": [
            150,
            159,
            15905
        ],
        "SrManageSubscriptionMappedOrganization": [
            150,
            159,
            15906
        ],
        "SrManageSubscriptionViewPackage": [
            150,
            159,
            15907
        ],
        "SrManageSubscriptionComparePackages": [
            150,
            159,
            15908
        ],
        "SrManageSubscriptionEditClientSubscription": [
            150,
            159,
            15910
        ],
        "SrManageRoles": [
            150,
            160,
            16001
        ],
        "SrManageRolesNewCustomRole": [
            150,
            160,
            16002
        ],
        "SrManageRolesEditCustomRole": [
            150,
            160,
            16003
        ],
        "SrManageRolesDeleteCustomRole": [
            150,
            160,
            16004
        ],
        "SrManageRolesMapNewOrganization": [
            150,
            160,
            16005
        ],
        "SrManageRolesUpdateOrder": [
            150,
            160,
            16006
        ],
        "SrManageRolesViewRole": [
            150,
            160,
            16007
        ],
        "SrManageRolesEditSystemRole": [
            150,
            160,
            16009
        ],
        "SrManageRolesMappedOrganization": [
            150,
            160,
            16010
        ],
        "SrManageRolesEditStatus": [
            150,
            160,
            16011
        ],
        "SrManageOrganization": [
            150,
            161,
            16101
        ],
        "SrManageOrganizationNewOrganization": [
            150,
            161,
            16102
        ],
        "SrManageOrganizationEditOrganization": [
            150,
            161,
            16103
        ],
        "SrManageOrganizationDeleteOrganization": [
            150,
            161,
            16104
        ],
        "SrManageOrganizationAddAdminUser": [
            150,
            161,
            16105
        ],
        "SrManageOrganizationBulkStatusUpdate": [
            150,
            161,
            16106
        ],
        "SrManageOrganizationEditStatus": [
            150,
            161,
            16107
        ],
        "SrManageOrganizationOrganizationSummary": [
            150,
            161,
            16108
        ],
        "SrManageOrganizationEditFeatureLabel": [
            150,
            161,
            16109
        ],
        "SrManageUser&GroupManageUsersandGroups": [
            150,
            162,
            16201
        ],
        "SrManageUser&GroupNewUser": [
            150,
            162,
            16202
        ],
        "SrManageUser&GroupEditUser": [
            150,
            162,
            16203
        ],
        "SrManageUser&GroupDeleteUser": [
            150,
            162,
            16204
        ],
        "SrManageUser&GroupResetPassword": [
            150,
            162,
            16205
        ],
        "SrManageUser&GroupNewGroup": [
            150,
            162,
            16206
        ],
        "SrManageUser&GroupEditGroup": [
            150,
            162,
            16207
        ],
        "SrManageUser&GroupDeleteGroup": [
            150,
            162,
            16208
        ],
        "SrManageUser&GroupBulkUserDelete": [
            150,
            162,
            16209
        ],
        "SrManageUser&GroupBulkUserUpdate": [
            150,
            162,
            16210
        ],
        "SrManageUser&GroupEditUserStatus": [
            150,
            162,
            16211
        ],
        "SrManageUser&GroupBulkGroupDelete": [
            150,
            162,
            16212
        ],
        "SrManageUser&GroupBulkGroupUpdate": [
            150,
            162,
            16213
        ],
        "SrManageUser&GroupEditGroupStatus": [
            150,
            162,
            16214
        ],
        "SrManageUser&GroupSendEmail": [
            150,
            162,
            16215
        ],
        "SrManageUser&GroupViewUser": [
            150,
            162,
            16216
        ],
        "SrManageUser&GroupViewGroup": [
            150,
            162,
            16217
        ],
        "SrManageUser&GroupGroupUsers": [
            150,
            162,
            16218
        ],
        "SrLiveScoringTracker": [
            400,
            403,
            40301
        ],
        "SrScoringMetrics": [
            400,
            404,
            40401
        ],
        "SrSetupReportListReportConfiguration": [
            550,
            554,
            55401
        ],
        "SrSetupReportListEditStatus": [
            550,
            554,
            55402
        ],
        "SrSetupReportListViewReport": [
            550,
            554,
            55403
        ],
        "SrSetupWorkflowFilterManageWorkflow": [
            550,
            555,
            55501
        ],
        "SrSetupWorkflowFilterEditStatus": [
            550,
            555,
            55502
        ],
        "SrManageTemplateManageTemplates": [
            600,
            603,
            60301
        ],
        "SrManageTemplateEditTemplate": [
            600,
            603,
            60302
        ],
        "SrManageTemplateEditStatus": [
            600,
            603,
            60303
        ],
        "SrManageTemplateViewTemplate": [
            600,
            603,
            60304
        ],
        "SrManageTemplateRestoreSystemDefault": [
            600,
            603,
            60305
        ],
        "SrManageEventManageNotifications": [
            600,
            605,
            60501
        ],
        "SrManageEventEditEvent": [
            600,
            605,
            60502
        ],
        "SrManageEventEditStatus": [
            600,
            605,
            60503
        ],
        "SrManageEventViewTemplate": [
            600,
            605,
            60504
        ],
        "SrManageEventViewEvent": [
            600,
            605,
            60505
        ],
        "SrUserProfileProfileSummary": [
            650,
            653,
            65301
        ],
        "SrUserProfileEditProfile": [
            650,
            653,
            65302
        ],
        "SrUserProfileSecurityCodeSend": [
            650,
            653,
            65303
        ],
        "SrUserProfileChangePassword": [
            650,
            653,
            65304
        ],
        "SrUserProfileMyAccount": [
            650,
            653,
            65305
        ],
        "SrLoginPageProfileSummary": [
            650,
            654,
            65401
        ],
        "SrLoginPageEditProfile": [
            650,
            654,
            65402
        ],
        "SrLoginPageSecurityCodeChange": [
            650,
            654,
            65403
        ],
        "SrLoginPageChangePassword": [
            650,
            654,
            65404
        ],
        "SrLoginPageMyAccount": [
            650,
            654,
            65405
        ],
        "SrClientPerformance": [
            750,
            753,
            75301
        ],
        "SrServiceManagement": [
            750,
            754,
            75401
        ]
    },
    "Client": {
        "ClientDashboard": [
            100,
            105,
            10501
        ],
        "ClientDasboardDashboard": [
            100,
            107,
            10701
        ],
        "ClientManageSubscription": [
            150,
            164,
            16401
        ],
        "ClientManageSubscriptionEditPlan": [
            150,
            164,
            16402
        ],
        "ClientManageSubscriptionSwitchPlan": [
            150,
            164,
            16403
        ],
        "ClientManageSubscriptionViewPlan": [
            150,
            164,
            16404
        ],
        "ClientManageRoles": [
            150,
            165,
            16501
        ],
        "ClientManageRolesNewCustomRole": [
            150,
            165,
            16502
        ],
        "ClientManageRolesEditCustomRole": [
            150,
            165,
            16503
        ],
        "ClientManageRolesDeleteCustomRole": [
            150,
            165,
            16504
        ],
        "ClientManageRolesUpdateOrder": [
            150,
            165,
            16505
        ],
        "ClientManageRolesViewRole": [
            150,
            165,
            16506
        ],
        "ClientManageRolesEditSystemRole": [
            150,
            165,
            16507
        ],
        "ClientManageRolesEditStatus": [
            150,
            165,
            16508
        ],
        "ClientManageOrganizationViewOrganization": [
            150,
            166,
            16601
        ],
        "ClientManageOrganizationEditOrganization": [
            150,
            166,
            16602
        ],
        "ClientManageOrganization": [
            150,
            166,
            16603
        ],
        "ClientManageUser&GroupManageUsersandGroups": [
            150,
            167,
            16701
        ],
        "ClientManageUser&GroupNewUser": [
            150,
            167,
            16702
        ],
        "ClientManageUser&GroupEditUser": [
            150,
            167,
            16703
        ],
        "ClientManageUser&GroupDeleteUser": [
            150,
            167,
            16704
        ],
        "ClientManageUser&GroupResetPassword": [
            150,
            167,
            16705
        ],
        "ClientManageUser&GroupNewGroup": [
            150,
            167,
            16706
        ],
        "ClientManageUser&GroupEditGroup": [
            150,
            167,
            16707
        ],
        "ClientManageUser&GroupDeleteGroup": [
            150,
            167,
            16708
        ],
        "ClientManageUser&GroupBulkUserDelete": [
            150,
            167,
            16709
        ],
        "ClientManageUser&GroupBulkUserUpdate": [
            150,
            167,
            16710
        ],
        "ClientManageUser&GroupEditUserStatus": [
            150,
            167,
            16711
        ],
        "ClientManageUser&GroupBulkGroupDelete": [
            150,
            167,
            16712
        ],
        "ClientManageUser&GroupBulkGroupUpdate": [
            150,
            167,
            16713
        ],
        "ClientManageUser&GroupEditGroupStatus": [
            150,
            167,
            16714
        ],
        "ClientManageUser&GroupSendEmail": [
            150,
            167,
            16715
        ],
        "ClientManageUser&GroupViewUser": [
            150,
            167,
            16716
        ],
        "ClientManageUser&GroupViewGroup": [
            150,
            167,
            16717
        ],
        "ClientManageUser&GroupGroupUsers": [
            150,
            167,
            16718
        ],
        "ClientManageClientMasterMangeClientMaster": [
            150,
            168,
            16801
        ],
        "ClientManageClientMasterEditMaster": [
            150,
            168,
            16802
        ],
        "ClientManageClientMasterNewItem": [
            150,
            168,
            16803
        ],
        "ClientManageClientMasterEditItem": [
            150,
            168,
            16804
        ],
        "ClientManageClientMasterDeleteItem": [
            150,
            168,
            16805
        ],
        "ClientManageClientMasterBulkUpload": [
            150,
            168,
            16806
        ],
        "ClientManageClientMasterUpdateOrder": [
            150,
            168,
            16807
        ],
        "ClientUnbilledAccountsUnbilledAccountReview": [
            200,
            201,
            20101
        ],
        "ClientUnbilledAccountsAccountListForward": [
            200,
            201,
            20102
        ],
        "ClientUnbilledAccountsAccountSave": [
            200,
            201,
            20103
        ],
        "ClientUnbilledAccountsAccountSubmit": [
            200,
            201,
            20104
        ],
        "ClientUnbilledAccountsAccountForward": [
            200,
            201,
            20105
        ],
        "ClientUnbilledAccountsMovetoWorkspace": [
            200,
            201,
            20106
        ],
        "ClientUnbilledAccountsAddComment": [
            200,
            201,
            20107
        ],
        "ClientUnbilledAccountsAccountDetails": [
            200,
            201,
            20108
        ],
        "ClientUnbilledAccountsSimilarAccounts": [
            200,
            201,
            20109
        ],
        "ClientBilledAccountsBilledAccountReview": [
            200,
            202,
            20201
        ],
        "ClientBilledAccountsAccountListForward": [
            200,
            202,
            20202
        ],
        "ClientBilledAccountsAccountSave": [
            200,
            202,
            20203
        ],
        "ClientBilledAccountsAccountSubmit": [
            200,
            202,
            20204
        ],
        "ClientBilledAccountsAccountForward": [
            200,
            202,
            20205
        ],
        "ClientBilledAccountsMovetoWorkspace": [
            200,
            202,
            20206
        ],
        "ClientBilledAccountsAddComment": [
            200,
            202,
            20207
        ],
        "ClientBilledAccountsAccountDetails": [
            200,
            202,
            20208
        ],
        "ClientBilledAccountsSimilarAccounts": [
            200,
            202,
            20209
        ],
        "ClientPriorityAccounts": [
            200,
            203,
            20301
        ],
        "ClientPriorityAccountsAccountListForward": [
            200,
            203,
            20302
        ],
        "ClientMyWorkspace": [
            250,
            251,
            25101
        ],
        "ClientMyWorkspaceAccountListForward": [
            250,
            251,
            25102
        ],
        "ClientAccountExplorer": [
            300,
            301,
            30101
        ],
        "ClientAccountExplorerNewSearch": [
            300,
            301,
            30102
        ],
        "ClientAccountExplorerEditSearch": [
            300,
            301,
            30103
        ],
        "ClientAccountExplorerSearchAccount": [
            300,
            301,
            30104
        ],
        "ClientMessageBoard": [
            350,
            351,
            35101
        ],
        "ClientMessageBoardNewMessage": [
            350,
            351,
            35102
        ],
        "ClientMessageBoardNewConversation": [
            350,
            351,
            35103
        ],
        "ClientMessageBoardMovetoArchive": [
            350,
            351,
            35104
        ],
        "ClientMessageBoardViewConversation": [
            350,
            351,
            35105
        ],
        "ClientMessageBoardViewBroadcast": [
            350,
            351,
            35106
        ],
        "ClientMessageBoardSU": [
            350,
            352,
            35201
        ],
        "ClientMessageBoardNewBroadcastSU": [
            350,
            352,
            35202
        ],
        "ClientMessageBoardNewConversationSU": [
            350,
            352,
            35203
        ],
        "ClientMessageBoardNewMessageSU": [
            350,
            352,
            35204
        ],
        "ClientMessageBoardMovetoArchiveSU": [
            350,
            352,
            35205
        ],
        "ClientMessageBoardViewConversationSU": [
            350,
            352,
            35206
        ],
        "ClientMessageBoardViewBroadcastSU": [
            350,
            352,
            35207
        ],
        "ClientLiveScoringTracker": [
            400,
            405,
            40501
        ],
        "ClientScoringMetrics": [
            400,
            406,
            40601
        ],
        "ClientSystemPerformance": [
            450,
            451,
            45101
        ],
        "ClientSystemPerformanceNewReport": [
            450,
            451,
            45102
        ],
        "ClientSystemPerformanceEditReport": [
            450,
            451,
            45103
        ],
        "ClientSystemPerformanceDeleteReport": [
            450,
            451,
            45104
        ],
        "ClientSystemPerformanceViewReport": [
            450,
            451,
            45105
        ],
        "ClientAuditorPerformance": [
            450,
            452,
            45201
        ],
        "ClientCodeAnalysis": [
            450,
            453,
            45301
        ],
        "ClientConfirmedChargeReport": [
            450,
            454,
            45401
        ],
        "ClientConfirmedChargeReportDailyReportSend": [
            450,
            454,
            45402
        ],
        "ClientSystemReportsDailyReportSend": [
            450,
            455,
            45501
        ],
        "ClientSystemReportsDailyConfirmedSend": [
            450,
            455,
            45502
        ],
        "ClientSystemReportsAccountSummarySend": [
            450,
            455,
            45503
        ],
        "ClientSystemReportsAccountReviewSend": [
            450,
            455,
            45504
        ],
        "ClientSystemReportsWeeklyReportSend": [
            450,
            455,
            45505
        ],
        "ClientIntegrationReportHL7ReportSend": [
            450,
            456,
            45601
        ],
        "ClientLiveSummary": [
            500,
            501,
            50101
        ],
        "ClientAuditorAssignment": [
            500,
            502,
            50201
        ],
        "ClientAuditorAssignmentEditAssignment": [
            500,
            502,
            50202
        ],
        "ClientAuditorAssignmentExecuteAssignment": [
            500,
            502,
            50203
        ],
        "ClientAuditorAssignmentDelegateAssignment": [
            500,
            502,
            50204
        ],
        "ClientAuditorAssignmentNewQueue": [
            500,
            502,
            50205
        ],
        "ClientAuditorAssignmentViewQueue": [
            500,
            502,
            50206
        ],
        "ClientAuditorAssignmentEditQueue": [
            500,
            502,
            50207
        ],
        "ClientAuditorAssignmentDeleteQueue": [
            500,
            502,
            50208
        ],
        "ClientReportUserAssignmentReportAssignment": [
            500,
            503,
            50301
        ],
        "ClientReportUserAssignmentEditAssignment": [
            500,
            503,
            50302
        ],
        "ClientAccountLifecycle": [
            500,
            504,
            50401
        ],
        "ClientAccountLifecycleViewAccount": [
            500,
            504,
            50402
        ],
        "ClientAccountLifecycleViewAccountLifecycle": [
            500,
            504,
            50403
        ],
        "ClientManageRules": [
            550,
            557,
            55701
        ],
        "ClientManageRulesNewRule": [
            550,
            557,
            55702
        ],
        "ClientManageRulesEditRule": [
            550,
            557,
            55703
        ],
        "ClientManageRulesDeleteRule": [
            550,
            557,
            55704
        ],
        "ClientManageRulesViewRule": [
            550,
            557,
            55705
        ],
        "ClientManageRulesCopyRule": [
            550,
            557,
            55706
        ],
        "ClientManageRulesPreviewRule": [
            550,
            557,
            55707
        ],
        "ClientManageCodeQueue": [
            550,
            558,
            55801
        ],
        "ClientManageCodeQueueNewQueue": [
            550,
            558,
            55802
        ],
        "ClientManageCodeQueueEditQueue": [
            550,
            558,
            55803
        ],
        "ClientManageCodeQueuePublishQueue": [
            550,
            558,
            55804
        ],
        "ClientManageCodeQueueViewQueue": [
            550,
            558,
            55805
        ],
        "ClientManageCodeQueueDeleteQueue": [
            550,
            558,
            55806
        ],
        "ClientManageCodeQueuePreviewQueue": [
            550,
            558,
            55807
        ],
        "ClientManageReportConfigurationReportConfiguration": [
            550,
            559,
            55901
        ],
        "ClientManageReportConfigurationEditReport": [
            550,
            559,
            55902
        ],
        "ClientManageReportConfigurationDeleteReport": [
            550,
            559,
            55903
        ],
        "ClientManageReportConfigurationEditStatus": [
            550,
            559,
            55904
        ],
        "ClientManageReportConfigurationNewChildReport": [
            550,
            559,
            55905
        ],
        "ClientManageReportConfigurationEditChildReport": [
            550,
            559,
            55906
        ],
        "ClientManageWorkflow": [
            550,
            560,
            56001
        ],
        "ClientManageWorkflowEditWorkflow": [
            550,
            560,
            56002
        ],
        "ClientManageWorkflowEditStatus": [
            550,
            560,
            56003
        ],
        "ClientManageWorkflowRestoreDefault": [
            550,
            560,
            56004
        ],
        "ClientManageWorkflowPreviewWorkflow": [
            550,
            560,
            56005
        ],
        "ClientManageNCCIRules": [
            550,
            562,
            56201
        ],
        "ClientManageNCCIRulesNewNCCIRule": [
            550,
            562,
            56202
        ],
        "ClientManageNCCIRulesEditNCCIRule": [
            550,
            562,
            56203
        ],
        "ClientManageNCCIRulesDeleteNCCIRule": [
            550,
            562,
            56204
        ],
        "ClientManageNCCIRulesViewNCCIRule": [
            550,
            562,
            56205
        ],
        "ClientManageNCCIRulesCopyNCCIRule": [
            550,
            562,
            56206
        ],
        "ClientManageNCCIRulesPreviewNCCIRule": [
            550,
            562,
            56207
        ],
        "ClientManageGeneralSettings": [
            550,
            563,
            56301
        ],
        "ClientManageGeneralSettingsEditFlagSettings": [
            550,
            563,
            56302
        ],
        "ClientManageGeneralSettingsEditGeneralSettings": [
            550,
            563,
            56303
        ],
        "ClientManageTemplateManageTemplates": [
            600,
            604,
            60401
        ],
        "ClientManageTemplateEditTemplate": [
            600,
            604,
            60402
        ],
        "ClientManageTemplateEditStatus": [
            600,
            604,
            60403
        ],
        "ClientManageTemplateViewTemplate": [
            600,
            604,
            60404
        ],
        "ClientManageTemplateRestoreSystemDefault": [
            600,
            604,
            60405
        ],
        "ClientManageEventManageNotifications": [
            600,
            606,
            60601
        ],
        "ClientManageEventEditEvent": [
            600,
            606,
            60602
        ],
        "ClientManageEventEditStatus": [
            600,
            606,
            60603
        ],
        "ClientManageEventViewTemplate": [
            600,
            606,
            60604
        ],
        "ClientManageEventViewEvent": [
            600,
            606,
            60605
        ],
        "ClientUserProfileForgetPassword": [
            650,
            655,
            65501
        ],
        "ClientUserProfileSecurityCodeSend": [
            650,
            655,
            65502
        ],
        "ClientUserProfileChangePassword": [
            650,
            655,
            65503
        ],
        "ClientLoginPageForgetPassword": [
            650,
            656,
            65601
        ],
        "ClientLoginPageSecurityCodeSend": [
            650,
            656,
            65602
        ],
        "ClientLoginPageChangePassword": [
            650,
            656,
            65603
        ],
        "ClientManageConfiguration": [
            700,
            701,
            70101
        ],
        "ClientManageConfigurationEditJobConfig": [
            700,
            701,
            70102
        ],
        "ClientManageConfigurationCloneJobConfiguration": [
            700,
            701,
            70103
        ],
        "ClientManageConfigurationDeleteJobConfiguration": [
            700,
            701,
            70104
        ],
        "ClientManageConfigurationNewJobConfiguration": [
            700,
            701,
            70105
        ],
        "ClientManageConfigurationViewJobConfiguration": [
            700,
            701,
            70106
        ],
        "ClientManageConfigurationViewSettings": [
            700,
            701,
            70107
        ],
        "ClientManageConfigurationEditSettings": [
            700,
            701,
            70108
        ],
        "ClientManageConfigurationManageStages": [
            700,
            701,
            70109
        ],
        "ClientManageConfigurationConfigurationValidation": [
            700,
            701,
            70110
        ],
        "ClientManageConfigurationEditSource": [
            700,
            701,
            70111
        ],
        "ClientManageConfigurationEditFileProperties": [
            700,
            701,
            70112
        ],
        "ClientManageConfigurationManageActivities": [
            700,
            701,
            70113
        ],
        "ClientManageConfigurationConfigureActivity": [
            700,
            701,
            70114
        ],
        "ClientManageConfigurationNewFilter": [
            700,
            701,
            70115
        ],
        "ClientManageConfigurationViewFilter": [
            700,
            701,
            70116
        ],
        "ClientManageConfigurationEditFilter": [
            700,
            701,
            70117
        ],
        "ClientViewJobHistory": [
            700,
            702,
            70201
        ],
        "ClientViewJobHistoryJobSummary": [
            700,
            702,
            70202
        ]
    }
}

type SPLinks = keyof typeof FeatureModuleTitleLabelingIds.SP;
type SRLinks = keyof typeof FeatureModuleTitleLabelingIds.SR;
type ClientLinks = keyof typeof FeatureModuleTitleLabelingIds.Client;


export class DynamicLabeling {
    systemModules: any[] = JSON.parse(new EncryptedStorage().getItem(new GlobalConfig().userModulesSubmodulesLSName));
    generate(orgType: "SP", link: SPLinks): string;
    generate(orgType: "SR", link: SRLinks): string;
    generate(orgType: "Client", link: ClientLinks): string;
    generate(orgType: "Auto", link: SPLinks | SRLinks | ClientLinks): string;
    generate(orgType, link) {
        const ids = FeatureModuleTitleLabelingIds[orgType][link]
        if(ids && ids.length == 3) {
            const module = this.systemModules.find(m => m.sysModuleId == ids[0]);
            if(module) {
                const submodule = module.subModuleList.find(sm => sm.sysSubModuleId == ids[1]);
                if(submodule) {
                  const labelAction = submodule.moduleActionList.find(action => action.sysActionId == ids[2]);
                  if(labelAction && labelAction.displayName) {
                    return labelAction.displayName;
                  }
                }
            }
        }
        return null;
    }
}