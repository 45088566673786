import * as _ from "lodash";
import { IRMNavItemConfig } from "../shared/components/rm-nav/rm-nav.model";
import { currentOrg } from "../shared/utils/current-org";
import { currentOrgType } from "../shared/utils/current-org-type";
import { currentUser } from "../shared/utils/current-user";
import { EncryptedStorage } from "../shared/utils/encrypted-storage";
import { GlobalConfig } from "./global-config";
import { FeatureModuleTitleLabelingIds } from "./feature-module-actions";

export const generateNavConfig = (): IRMNavItemConfig[] => {
  const org = currentOrg();
  return [
    {
      moduleId: 0,
      route: "/my-account",
      SubHeaderOptions: {
        title: "My Account",
        labelIds: null,
        breadcrumb: [
          { label: "Home", labelIds: null, isActive: false, route: "/dashboard" },
          { label: "My Account", labelIds: null, isActive: true },
        ],
      },
    },
    {
      moduleId: FeatureModuleTitleLabelingIds.SP.SpDashboard[0],
      icon: "Module-Dashboard",
      children: [
        //ADMIN SP
        // {
        //   moduleId: 101,
        //   route: "/dashboard",
        //   SubHeaderOptions: {
        //     title: "SP Dashboard",
        //     labelIds: FeatureModuleTitleLabelingIds.SP.SpDashboard,
        //     breadcrumb: [],
        //   },
        // },
        //SR Dashboard
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrDashboard[1],
          route: "/dashboard/sr",
          SubHeaderOptions: {
            title: "SR Dashboard",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrDashboard,
            breadcrumb: [
              {
                label: "Home",
                isActive: true,
                route: "/dashboard/sr",
                labelIds: null
              },
              {
                label: "Dashboard",
                labelIds: FeatureModuleTitleLabelingIds.SR.SrDashboard,
                isActive: true
              },
            ],
          },
        },
        // Client Admin Dashboard
        // {
        //   moduleId: 0,
        //   route: "/dashboard",
        //   SubHeaderOptions: {
        //     title: "Dashboard",
        //     breadcrumb: [],
        //   },
        // },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpDashboard[1],
          route: "/dashboard/sp",
          SubHeaderOptions: {
            title: "SP Dashboard",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpDashboard,
            breadcrumb: [
              {
                label: "Home",
                isActive: true,
                labelIds: null,
                route: "/dashboard/sp",
              },
              { label: "Dashboard", labelIds: FeatureModuleTitleLabelingIds.SP.SpDashboard, isActive: true },
            ],
          },
        },
        // Auditor Dashboard
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientDasboardDashboard[1],
          route: "/dashboard/auditor",
          SubHeaderOptions: {
            title: "Auditor Dashboard",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientDasboardDashboard,
            breadcrumb: [
              {
                label: "Home",
                isActive: false,
                labelIds: null,
                route: "/dashboard/auditor",
              },
              { label: "Dashboard", labelIds: FeatureModuleTitleLabelingIds.Client.ClientDasboardDashboard, isActive: true },
            ],
          },
        },
        // Client Admin Dashboard
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientDashboard[1],
          route: "/dashboard/client-admin",
          SubHeaderOptions: {
            title: "Client Admin Dashboard",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientDashboard,
            breadcrumb: [
              {
                label: "Home",
                isActive: false,
                route: "/dashboard/client-admin",
                labelIds: null,
              },
              { label: "Dashboard", labelIds: FeatureModuleTitleLabelingIds.Client.ClientDashboard, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientDashboard[1],
          route: "/dashboard/supervisor",
          SubHeaderOptions: {
            title: "Supervisor Dashboard",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientDashboard,
            breadcrumb: [
              {
                label: "Home",
                isActive: false,
                route: "/dashboard/supervisor",
                labelIds: null,
              },
              { label: "Dashboard", labelIds: FeatureModuleTitleLabelingIds.Client.ClientDashboard, isActive: true },
            ],
          },
        },
      ],
    },
    {
      moduleId: FeatureModuleTitleLabelingIds.SP.SpManageSystemMaster[0],
      icon: "administrator",
      children: [
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageServer[1],
          route: "/manage-server",
          SubHeaderOptions: {
            title: "Manage Server",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageServer,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Server", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageServer, isActive: true },
            ],
          },
        },
        // Manage Master SP
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageSystemMaster[1],
          route: "/manage-master/sp",
          SubHeaderOptions: {
            title: "Manage System Master (SP)",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSystemMaster,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard", labelIds: null, },
              { label: "Manage Master", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSystemMaster, isActive: true },
            ],
          },
        },
        // Manage Subscription SP
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageSubscription[1],
          route: "/manage-subscription/sp",
          SubHeaderOptions: {
            title: "Manage Subscription (SP)",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSubscription,
            breadcrumb: [
              { label: "Home", isActive: false, labelIds: null, route: "/dashboard" },
              { label: "Manage Subscription", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSubscription, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageSubscriptionNewPackage[1],
          route: "/manage-subscription/sp/new-package",
          SubHeaderOptions: {
            title: "New Package",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSubscriptionNewPackage,
            breadcrumb: [
              { label: "Home", isActive: false, labelIds: null, route: "/dashboard" },
              {
                label: "Manage Subscription",
                isActive: false,
                route: "/manage-subscription/sp",
                labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSubscription,
                queryRoute: { view: "package" },
              },
              { label: "Add Package", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSubscriptionNewPackage, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageSubscriptionEditPackage[1],
          route: "/manage-subscription/sp/edit-package",
          SubHeaderOptions: {
            title: "Edit Package",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSubscriptionEditPackage,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Subscription",
                isActive: false,
                route: "/manage-subscription/sp",
                labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSubscription,
                queryRoute: { view: "package" },
              },
              { label: "Edit Package", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSubscriptionEditPackage, isActive: true },
            ],
          },
        },
        // Manage Feature SP
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageFeatureModuleManageFeature[1],
          route: "/manage-feature",
          SubHeaderOptions: {
            title: "Manage Feature",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageFeatureModuleManageFeature,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Feature", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageFeatureModuleManageFeature, isActive: true },
            ],
          },
        },
        // Manage Roles SP
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageRoles[1],
          route: "/manage-roles",
          SubHeaderOptions: {
            title: "Manage Roles (SP)",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageRoles,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Roles", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageRoles, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageRolesNewRole[1],
          route: "/manage-roles/create-role",
          SubHeaderOptions: {
            title: "Create New Role",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageRolesNewRole,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Roles", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageRoles, isActive: false, route: "/manage-roles" },
              { label: "Create New Role", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageRolesNewRole, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageRolesEditRole[1],
          route: "/manage-roles/edit-role",
          SubHeaderOptions: {
            title: "Edit Role",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageRolesEditRole,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Roles", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageRoles, isActive: false, route: "/manage-roles" },
              { label: "Edit Role", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageRolesEditRole, isActive: true },
            ],
          },
        },
        // Manage Organization (SP)
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageOrganization[1],
          route: "/manage-org/sp",
          SubHeaderOptions: {
            title: "Manage Organization (SP)",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageOrganization,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Organization", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageOrganization, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageOrganizationNewOrganization[1],
          route: "/manage-org/create-sr-org",
          SubHeaderOptions: {
            title: "Add New Organisation",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageOrganizationNewOrganization,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Organisation",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.SP.SpManageOrganization,
                route: "/manage-org/sp",
              },
              { label: "Add New Organisation (SR)", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageOrganizationNewOrganization, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageOrganizationEditOrganization[1],
          route: "/manage-org/edit-org",
          SubHeaderOptions: {
            title: "Edit Organisation",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageOrganizationEditOrganization,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Organisation",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.SP.SpManageOrganization,
                route: "/manage-org/sp",
              },
              { label: "Edit Organisation", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageOrganizationEditOrganization, isActive: true },
            ],
          },
        },
        //   Manage Users & Groups (SP)
        {
          moduleId: FeatureModuleTitleLabelingIds.SP["SpManageUser&GroupManageUsersandGroups"][1],
          route: "/manage-user-and-group/sp",
          SubHeaderOptions: {
            title: "Manage Users and Groups",
            labelIds: FeatureModuleTitleLabelingIds.SP["SpManageUser&GroupManageUsersandGroups"],
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Users and Groups", labelIds: FeatureModuleTitleLabelingIds.SP["SpManageUser&GroupManageUsersandGroups"], isActive: true },
            ],
          },
        },
        // ADMIN SR
        // Manage Subscription (SR)
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrManageSubscription[1],
          route: "/manage-subscription/sr",
          SubHeaderOptions: {
            title: "Manage Subscription (SR)",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrManageSubscription,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Subscription", labelIds: FeatureModuleTitleLabelingIds.SR.SrManageSubscription, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrManageSubscriptionNewCustomPackage[1],
          route: "/manage-subscription/sr/custom-package",
          SubHeaderOptions: {
            title: "Custom Package",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrManageSubscriptionNewCustomPackage,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Subscription",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.SR.SrManageSubscription,
                route: "/manage-subscription/sr",
              },
              { label: "Add Package", labelIds: FeatureModuleTitleLabelingIds.SR.SrManageSubscriptionNewCustomPackage, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrManageSubscriptionEditCustomPackage[1],
          route: "/manage-subscription/sr/edit-package",
          SubHeaderOptions: {
            title: "Edit Package",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrManageSubscriptionEditCustomPackage,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Subscription",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.SR.SrManageSubscription,
                route: "/manage-subscription/sr",
              },
              { label: "Edit Package", labelIds: FeatureModuleTitleLabelingIds.SR.SrManageSubscriptionEditCustomPackage, isActive: true },
            ],
          },
        },
        // Manage Roles (SR)
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrManageRoles[1],
          route: "/manage-roles/sr",
          SubHeaderOptions: {
            title: "Manage Roles (SR)",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrManageRoles,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Roles", labelIds: FeatureModuleTitleLabelingIds.SR.SrManageRoles, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrManageRolesNewCustomRole[1],
          route: "/manage-roles/sr/custom/create-role",
          SubHeaderOptions: {
            title: "Create Custom Role",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrManageRolesNewCustomRole,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Roles",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.SR.SrManageRoles,
                route: "/manage-roles/sr",
              },
              { label: "Create Role", labelIds: FeatureModuleTitleLabelingIds.SR.SrManageRolesNewCustomRole, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrManageRolesEditCustomRole[1],
          route: "/manage-roles/sr/custom/edit-role",
          SubHeaderOptions: {
            title: "Edit Custom Role",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrManageRolesEditCustomRole,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Roles",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.SR.SrManageRoles,
                route: "/manage-roles/sr",
              },
              { label: "Edit Role", labelIds: FeatureModuleTitleLabelingIds.SR.SrManageRolesEditCustomRole, isActive: true },
            ],
          },
        },
        // Manage Org (SR)
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrManageOrganization[1],
          route: "/manage-org/sr",
          SubHeaderOptions: {
            title: "Manage Organisation (SR)",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrManageOrganization,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Organisation", labelIds: FeatureModuleTitleLabelingIds.SR.SrManageOrganization, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrManageOrganizationNewOrganization[1],
          route: "/manage-org/create-client-org",
          SubHeaderOptions: {
            title: "Add New Organisation",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrManageOrganizationNewOrganization,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Organisation",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.SR.SrManageOrganization,
                route: "/manage-org/sr",
              },
              { label: "Add New Organisation", labelIds: FeatureModuleTitleLabelingIds.SR.SrManageOrganizationNewOrganization, isActive: true },
            ],
          },
        },
        // Manage User & Group (SR)
        {
          moduleId: FeatureModuleTitleLabelingIds.SR["SrManageUser&GroupManageUsersandGroups"][1],
          route: "/manage-user-and-group/sr",
          SubHeaderOptions: {
            title: "Manage Users and Groups (SR)",
            labelIds: FeatureModuleTitleLabelingIds.SR["SrManageUser&GroupManageUsersandGroups"],
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Users and Groups (SR)", labelIds: FeatureModuleTitleLabelingIds.SR["SrManageUser&GroupManageUsersandGroups"], isActive: true },
            ],
          },
        },
        //ADMIN Client
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageClientMasterMangeClientMaster[1],
          route: "/manage-master/client",
          SubHeaderOptions: {
            title: "Manage Client Master",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageClientMasterMangeClientMaster,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Master", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageClientMasterMangeClientMaster, isActive: true },
            ],
          },
        },
        // Manage Subscription Client
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageSubscription[1],
          route: "/manage-subscription/client",
          SubHeaderOptions: {
            title: "Manage Subscription (CL)",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageSubscription,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Subscription", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageSubscription, isActive: true },
            ],
          },
        },
        // Manage Roles (Client)
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageRoles[1],
          route: "/manage-roles/client",
          SubHeaderOptions: {
            title: "Manage Roles (CL)",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRoles,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Roles", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRoles, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageRolesNewCustomRole[1],
          route: "/manage-roles/client/custom/create-role",
          SubHeaderOptions: {
            title: "Create Custom Role",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRolesNewCustomRole,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Roles",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRoles,
                route: "/manage-roles/client",
              },
              { label: "Create Role", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRolesNewCustomRole, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageRolesEditCustomRole[1],
          route: "/manage-roles/client/custom/edit-role",
          SubHeaderOptions: {
            title: "Create Custom Role",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRolesEditCustomRole,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Roles",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRoles,
                route: "/manage-roles/client",
              },
              { label: "Edit Role", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRolesEditCustomRole, isActive: true },
            ],
          },
        },
        // Manage Organization (Client)
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageOrganization[1],
          route: "/manage-org/client",
          SubHeaderOptions: {
            title: `${org?.name || "Manage Organisation"}`,
            // title: "Manage Organization (CL)",
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Organization", isActive: true },
            ],
          },
        },
        // Manage Users and Groups (Client)
        {
          moduleId: FeatureModuleTitleLabelingIds.Client["ClientManageUser&GroupManageUsersandGroups"][1],
          route: "/manage-user-and-group/client",
          SubHeaderOptions: {
            title: "Manage Users and Groups (CL)",
            labelIds: FeatureModuleTitleLabelingIds.Client["ClientManageUser&GroupManageUsersandGroups"],
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Users and Groups", labelIds: FeatureModuleTitleLabelingIds.Client["ClientManageUser&GroupManageUsersandGroups"], isActive: true },
            ],
          },
        },
      ],
    },
    {
      moduleId: FeatureModuleTitleLabelingIds.Client.ClientUnbilledAccountsUnbilledAccountReview[0],
      icon: "Account-View",
      children: [
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientUnbilledAccountsUnbilledAccountReview[1],
          route: "/account-review/unbilled",
          SubHeaderOptions: {
            title: "Account Review - Unbilled",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientUnbilledAccountsUnbilledAccountReview,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Account Review - Unbilled", labelIds: FeatureModuleTitleLabelingIds.Client.ClientUnbilledAccountsUnbilledAccountReview, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientBilledAccountsBilledAccountReview[1],
          route: "/account-review/billed",
          SubHeaderOptions: {
            title: "Account Review - Billed",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientBilledAccountsBilledAccountReview,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Account Review - Billed", labelIds: FeatureModuleTitleLabelingIds.Client.ClientBilledAccountsBilledAccountReview, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientPriorityAccounts[1],
          route: "/account-review/priority",
          SubHeaderOptions: {
            title: "Account Review - Priority",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientPriorityAccounts,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Account Review - Priority", labelIds: FeatureModuleTitleLabelingIds.Client.ClientPriorityAccounts, isActive: true },
            ],
          },
        },
        {
          moduleId: null,
          route: "/account-review/details",
          SubHeaderOptions: {
            title: "Account Details",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientUnbilledAccountsAccountDetails,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Account Review - Details", labelIds: FeatureModuleTitleLabelingIds.Client.ClientUnbilledAccountsAccountDetails, isActive: true },
            ],
          },
        },
        {
          moduleId: null,
          route: "/account-review/details/similar",
          SubHeaderOptions: {
            title: "Similar Accounts",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientUnbilledAccountsAccountDetails,
            isBackOption: true,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Account Review", isActive: true },
            ],
          },
        },
      ],
    },
    // Message Board
    {
      moduleId: FeatureModuleTitleLabelingIds.Client.ClientMessageBoard[0],
      icon: "Message-Board",
      children: [
        // Auditor
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientMessageBoard[1],
          route: "/message-board",
          SubHeaderOptions: {
            title: "Message Board",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientMessageBoard,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Message Board", labelIds: FeatureModuleTitleLabelingIds.Client.ClientMessageBoard, isActive: true },
            ],
          },
        },
        // Supervisor
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientMessageBoardSU[1],
          route: "/message-board",
          SubHeaderOptions: {
            title: "Message Board",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientMessageBoard,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Message Board", labelIds: FeatureModuleTitleLabelingIds.Client.ClientMessageBoard, isActive: true },
            ],
          },
        },
      ],
    },
    {
      moduleId: FeatureModuleTitleLabelingIds.Client.ClientMyWorkspace[0],
      icon: "My-Workspace",
      children: [
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientMyWorkspace[1],
          route: "/workspace",
          SubHeaderOptions: {
            title: "My Workspace",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientMyWorkspace,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Workspace", labelIds: FeatureModuleTitleLabelingIds.Client.ClientMyWorkspace, isActive: true },
            ],
          },
        },
      ],
    },
    {
      moduleId: FeatureModuleTitleLabelingIds.Client.ClientLiveScoringTracker[0],
      icon: "insights",
      children: [
        // client scoring insights
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpLiveScoringTracker[1],
          route: "/client-scoring-status",
          SubHeaderOptions: {
            title: "Live Scoring Tracker",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpLiveScoringTracker,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Scoring Insight", labelIds: FeatureModuleTitleLabelingIds.SP.SpLiveScoringTracker, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrLiveScoringTracker[1],
          route: "/client-scoring-status/sr",
          SubHeaderOptions: {
            title: "Live Scoring Tracker",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrLiveScoringTracker,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Scoring Insight", labelIds: FeatureModuleTitleLabelingIds.SR.SrLiveScoringTracker, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientLiveScoringTracker[1],
          route: "/client-scoring-status/client",
          SubHeaderOptions: {
            title: "Live Scoring Tracker",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientLiveScoringTracker,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Scoring Insight", labelIds: FeatureModuleTitleLabelingIds.Client.ClientLiveScoringTracker, isActive: true },
            ],
          },
        },
        // client scoring insights
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpScoringMetrics[1],
          route: "/client-scoring-metrics",
          SubHeaderOptions: {
            title: "Scoring Metrics",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpScoringMetrics,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Scoring Insight", labelIds: FeatureModuleTitleLabelingIds.SP.SpScoringMetrics, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrScoringMetrics[1],
          route: "/client-scoring-metrics/sr",
          SubHeaderOptions: {
            title: "Scoring Metrics",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrScoringMetrics,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Scoring Insight", labelIds: FeatureModuleTitleLabelingIds.SR.SrScoringMetrics, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientScoringMetrics[1],
          route: "/client-scoring-metrics/client",
          SubHeaderOptions: {
            title: "Scoring Metrics",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientScoringMetrics,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Scoring Insight", labelIds: FeatureModuleTitleLabelingIds.Client.ClientScoringMetrics, isActive: true },
            ],
          },
        },
      ],
    },
    {
      moduleId: FeatureModuleTitleLabelingIds.Client.ClientConfirmedChargeReport[0],
      icon: "report",
      children: [
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientConfirmedChargeReport[1],
          route: "/report",
          SubHeaderOptions: {
            title: "Confirmed Charge Report",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientConfirmedChargeReport,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Confirmed Charge Report", labelIds: FeatureModuleTitleLabelingIds.Client.ClientConfirmedChargeReport, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientSystemPerformance[1],
          route: "/report/system-performance",
          SubHeaderOptions: {
            title: "System Performance",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientSystemPerformance,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Report", labelIds: FeatureModuleTitleLabelingIds.Client.ClientSystemPerformance, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientAuditorPerformance[1],
          route: "/report/auditor-performance",
          SubHeaderOptions: {
            title: "Auditor Performance",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientAuditorPerformance,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Auditor Performance", labelIds: FeatureModuleTitleLabelingIds.Client.ClientAuditorPerformance, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientCodeAnalysis[1],
          route: "/report/code-analysis",
          SubHeaderOptions: {
            title: "Code Analysis",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientCodeAnalysis,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Code Analysis", labelIds: FeatureModuleTitleLabelingIds.Client.ClientCodeAnalysis, isActive: true },
            ],
          },
        },
      ],
    },
    {
      moduleId: FeatureModuleTitleLabelingIds.Client.ClientLiveSummary[0],
      icon: "Supervision-Tools",
      children: [
        // supervision-tools
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientLiveSummary[1],
          route: "/supervision-tools/live-summary",
          SubHeaderOptions: {
            title: "Live Summary",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientLiveSummary,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Live Summary", labelIds: FeatureModuleTitleLabelingIds.Client.ClientLiveSummary, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientReportUserAssignmentReportAssignment[1],
          route: "/supervision-tools/report-user-assignment",
          SubHeaderOptions: {
            title: "Report User Assignment",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientReportUserAssignmentReportAssignment,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Report User Assignment", labelIds: FeatureModuleTitleLabelingIds.Client.ClientReportUserAssignmentReportAssignment, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientAccountLifecycle[1],
          route: "/supervision-tools/account-search",
          SubHeaderOptions: {
            title: "Account Lifecycle",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientAccountLifecycle,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Account Lifecycle", labelIds: FeatureModuleTitleLabelingIds.Client.ClientAccountLifecycle, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignment[1],
          route: "/supervision-tools/auditor-assignment",
          SubHeaderOptions: {
            title: "Auditor Assignment",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignment,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Auditor Assignment", labelIds: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignment, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignmentNewQueue[1],
          route: "/supervision-tools/auditor-assignment/add-queue",
          SubHeaderOptions: {
            title: "Add New Queue",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignmentNewQueue,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Auditor Assignment",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignment,
                route: "/supervision-tools/auditor-assignment",
              },
              { label: "Auditor Queue", labelIds: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignmentNewQueue, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignmentEditQueue[1],
          route: "/supervision-tools/auditor-assignment/edit-queue",
          SubHeaderOptions: {
            title: "Edit New Queue",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignmentEditQueue,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Auditor Assignment",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignment,
                route: "/supervision-tools/auditor-assignment",
              },
              { label: "Auditor Queue", labelIds: FeatureModuleTitleLabelingIds.Client.ClientAuditorAssignmentEditQueue, isActive: true },
            ],
          },
        },
      ],
    },
    {
      // Configurations
      moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageRules[0],
      icon: "settings",
      children: [
        // Manage System Flags
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageSystemSettings[1],
          route: "/configurations/system-settings",
          SubHeaderOptions: {
            title: "Manage System Settings",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSystemSettings,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "System Settings", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageSystemSettings, isActive: true },
            ],
          },
        },
        // Manage System Settings
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageGeneralSettings[1],
          route: "/configurations/manage-system-flags",
          SubHeaderOptions: {
            title: "Manage System Settings",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageGeneralSettings,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "System Settings", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageGeneralSettings, isActive: true },
            ],
          },
        },
        //Client Workflow
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageWorkflow[1],
          route: "/configurations/client-workflow",
          SubHeaderOptions: {
            title: "Manage Workflow",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageWorkflow,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Configurations", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageWorkflow, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageWorkflowEditWorkflow[1],
          route: "/configurations/client-workflow/edit-workflow",
          SubHeaderOptions: {
            title: "Edit Workflow",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageWorkflowEditWorkflow,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Configurations", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageWorkflow, route: "/configurations/client-workflow", isActive: false },
              { label: "Edit Workflow", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageWorkflowEditWorkflow, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterManageWorkflow[1],
          route: "/configurations/sp-workflow",
          SubHeaderOptions: {
            title: "Manage Workflow",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterManageWorkflow,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Configurations", labelIds: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterManageWorkflow, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterEditWorkflow[1],
          route: "/configurations/sp-workflow/edit-workflow",
          SubHeaderOptions: {
            title: "Edit Workflow",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterEditWorkflow,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Configurations", labelIds: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterManageWorkflow, route: "/configurations/sp-workflow", isActive: false },
              { label: "Edit Workflow", labelIds: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterEditWorkflow, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterNewWorkflow[1],
          route: "/configurations/sp-workflow/add-workflow",
          SubHeaderOptions: {
            title: "Add Workflow",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterNewWorkflow,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Configurations", labelIds: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterManageWorkflow, route: "/configurations/sp-workflow", isActive: false },
              { label: "Add Workflow", labelIds: FeatureModuleTitleLabelingIds.SP.SpSetupWorkflowFilterNewWorkflow, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrSetupWorkflowFilterManageWorkflow[1],
          route: "/configurations/sr-workflow",
          SubHeaderOptions: {
            title: "Manage Workflow",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrSetupWorkflowFilterManageWorkflow,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Configurations", labelIds: FeatureModuleTitleLabelingIds.SR.SrSetupWorkflowFilterManageWorkflow, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageCodeQueue[1],
          route: "/configurations/published-codes",
          SubHeaderOptions: {
            title: "Manage Code Queues",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageCodeQueue,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Configurations", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageCodeQueue, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpSetupReportListReportConfiguration[1],
          route: "/configurations/sp-report",
          SubHeaderOptions: {
            title: "SP Report Configuration",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpSetupReportListReportConfiguration,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Configurations", labelIds: FeatureModuleTitleLabelingIds.SP.SpSetupReportListReportConfiguration, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrSetupReportListReportConfiguration[1],
          route: "/configurations/sr-report",
          SubHeaderOptions: {
            title: "SR Report Configuration",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrSetupReportListReportConfiguration,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Configurations", labelIds: FeatureModuleTitleLabelingIds.SR.SrSetupReportListReportConfiguration, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageRules[1],
          route: "/configurations/manage-rules",
          SubHeaderOptions: {
            title: "Manage Rules",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRules,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Rules", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRules, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageRulesNewRule[1],
          route: "/configurations/manage-rules/add-rule",
          SubHeaderOptions: {
            title: "Add Rule",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRulesNewRule,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Rules", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRules, route: "/configurations/manage-rules", isActive: false },
              { label: "Add Rule", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRulesNewRule, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageRulesEditRule[1],
          route: "/configurations/manage-rules/edit-rule",
          SubHeaderOptions: {
            title: "Edit Rule",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRulesEditRule,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Rules", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRules, route: "/configurations/manage-rules", isActive: false },
              { label: "Edit Rule", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageRulesEditRule, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationReportConfiguration[1],
          route: "/configurations/client-report",
          SubHeaderOptions: {
            title: "Client Report Configuration",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationReportConfiguration,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Configurations", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationReportConfiguration, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationEditReport[1],
          route: "/configurations/client-report/edit-client-report",
          SubHeaderOptions: {
            title: "Edit Report",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationEditReport,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Configurations",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationReportConfiguration,
                route: "/configurations/client-report",
              },
              { label: "Edit Report", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationEditReport, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationNewChildReport[1],
          route: "/configurations/client-report/child-client-report",
          SubHeaderOptions: {
            title: "Child Report Configuration",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationNewChildReport,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Configurations",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationReportConfiguration,
                route: "/configurations/client-report",
              },
              { label: "Child Report", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageReportConfigurationNewChildReport, isActive: true },
            ],
          },
        },
      ],
    },
    {
      // Manage Notification
      moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageEventManageNotifications[0],
      icon: "notification",
      children: [
        // Manage Notification (Events)
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageEventManageNotifications[1],
          route: "/notification/events",
          SubHeaderOptions: {
            title: "Manage Notificaton (SP)",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageEventManageNotifications,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Notificaton (SP)", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageEventManageNotifications, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrManageEventManageNotifications[1],
          route: "/notification/events/sr",
          SubHeaderOptions: {
            title: "Manage Notificaton (SR)",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrManageEventManageNotifications,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Notificaton (SR)", labelIds: FeatureModuleTitleLabelingIds.SR.SrManageEventManageNotifications, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageEventManageNotifications[1],
          route: "/notification/events/client",
          SubHeaderOptions: {
            title: "Manage Notificaton (Client)",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageEventManageNotifications,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Manage Notificaton (Client)", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageEventManageNotifications, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageEventNewEvent[1],
          route: "/notification/events/system-add",
          SubHeaderOptions: {
            title: "Manage Notificaton (SP)",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageEventNewEvent,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Notificaton",
                route: "/notification/events",
                labelIds: FeatureModuleTitleLabelingIds.SP.SpManageEventManageNotifications,
                isActive: false,
              },
              { label: "Create Event", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageEventNewEvent, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageEventEditEvent[1],
          route: "/notification/events/system-edit",
          SubHeaderOptions: {
            title: "Manage Notificaton (SP)",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageEventEditEvent,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Notificaton",
                route: "/notification/events",
                labelIds: FeatureModuleTitleLabelingIds.SP.SpManageEventManageNotifications,
                isActive: false,
              },
              { label: "Edit Event", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageEventEditEvent, isActive: true },
            ],
          },
        },
        // Manage Notifications >> Templates
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageTemplateManageTemplates[1],
          route: "/notification/templates/sp",
          SubHeaderOptions: {
            title: "Manage Templates",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageTemplateManageTemplates,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Templates", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageTemplateManageTemplates, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SR.SrManageTemplateManageTemplates[1],
          route: "/notification/templates/sr",
          SubHeaderOptions: {
            title: "Manage Templates",
            labelIds: FeatureModuleTitleLabelingIds.SR.SrManageTemplateManageTemplates,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Templates", labelIds: FeatureModuleTitleLabelingIds.SR.SrManageTemplateManageTemplates, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageTemplateManageTemplates[1],
          route: "/notification/templates/client",
          SubHeaderOptions: {
            title: "Manage Templates",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageTemplateManageTemplates,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Templates", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageTemplateManageTemplates, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.SP.SpManageTemplateNewTemplate[1],
          route: "/notification/templates/add-template",
          SubHeaderOptions: {
            title: "Add New Template",
            labelIds: FeatureModuleTitleLabelingIds.SP.SpManageTemplateNewTemplate,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Templates",
                isActive: false,
                labelIds: FeatureModuleTitleLabelingIds.SP.SpManageTemplateManageTemplates,
                route: "/notification/templates/sp",
              },
              { label: "Create Template", labelIds: FeatureModuleTitleLabelingIds.SP.SpManageTemplateNewTemplate, isActive: true },
            ],
          },
        },
        {
          moduleId: 602,
          route: "/notification/templates/edit-sys-template",
          SubHeaderOptions: {
            title: "Edit Template",
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Notifications", isActive: false, route: "/notification" },
              {
                label: "Manage Templates",
                isActive: false,
                route: "/notification/templates/sp",
              },
              { label: "Edit Template", isActive: true },
            ],
          },
        },
        {
          moduleId: 602,
          route: "/notification/templates/edit-template",
          SubHeaderOptions: {
            title: "Edit Template",
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              {
                label: "Manage Templates",
                isActive: false,
                route: `/notification/templates/${currentOrgType()}`,
              },
              { label: "Edit Template", isActive: true },
            ],
          },
        },
      ],
    },
    // {
    //   moduleId: 650,
    //   icon: "Client-Management",
    //   children: [],
    // },
    // {
    //   moduleId: 700,
    //   icon: "System-Monitoring",
    //   children: [],
    // },
    {
      moduleId: FeatureModuleTitleLabelingIds.Client.ClientAccountExplorer[0], //Temp
      icon: "Account-Explorer",
      children: [
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientAccountExplorer[1],
          route: "/account-review/explorer/search",
          SubHeaderOptions: {
            title: "Account Explorer",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientAccountExplorer,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Account Explorer", labelIds: FeatureModuleTitleLabelingIds.Client.ClientAccountExplorer, isActive: true },
            ],
          },
        },
      ],
    },
    // {
    //   // Manage Notification
    //   moduleId: 900,
    //   icon: "notification",
    //   children: []
    // },
    {
      moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageConfiguration[0],
      icon: "dataflow",
      children: [
        // Manage Dataflow
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageConfiguration[1],
          route: "/dataflow",
          SubHeaderOptions: {
            title: "Data Flow",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfiguration,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Data Flow", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfiguration, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationNewJobConfiguration[1],
          route: "/dataflow/add-new-job",
          SubHeaderOptions: {
            title: "Add Job Configuration",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationNewJobConfiguration,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Data Flow", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfiguration, isActive: false, route: "/dataflow" },
              { label: "Add Job Configuration", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationNewJobConfiguration, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationCloneJobConfiguration[1],
          route: "/dataflow/copy-job",
          SubHeaderOptions: {
            title: "Add Job Configuration",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationCloneJobConfiguration,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Data Flow", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfiguration, isActive: false, route: "/dataflow" },
              { label: "Add Job Configuration", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationCloneJobConfiguration, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationEditJobConfig[1],
          route: "/dataflow/edit-job",
          SubHeaderOptions: {
            title: "Edit Job Configuration",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationEditJobConfig,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Data Flow", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfiguration, isActive: false, route: "/dataflow" },
              { label: "Edit Job Configuration", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationEditJobConfig, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationViewSettings[1], // Temp
          route: "/dataflow/system-settings",
          SubHeaderOptions: {
            title: "Data Flow - System Setting",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationViewSettings,
            breadcrumb: [
              { label: "Home", isActive: false, route: "/dashboard" },
              { label: "Data Flow", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfiguration, isActive: false, route: "/dataflow" },
              { label: "System Setting", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfigurationViewSettings, isActive: true },
            ],
          },
        },
        {
          moduleId: FeatureModuleTitleLabelingIds.Client.ClientViewJobHistory[1],
          route: "/dataflow/job-run-history",
          SubHeaderOptions: {
            title: "Job Run History",
            labelIds: FeatureModuleTitleLabelingIds.Client.ClientViewJobHistory,
            breadcrumb: [
              { label: "Data Flow", labelIds: FeatureModuleTitleLabelingIds.Client.ClientManageConfiguration, isActive: true },
              { label: "Job Run History", labelIds: FeatureModuleTitleLabelingIds.Client.ClientViewJobHistory, isActive: true },
            ],
          },
        },
  
      ],
      // route: "/dataflow",
      // SubHeaderOptions: {
      //   title: "Dataflow",
      //   breadcrumb: [
      //     { label: "Home", isActive: false, route: "/dashboard" },
      //     { label: "Dataflow", isActive: true },
      //   ],
      // },
    },
    // {
    //   moduleId: 759, //Temp
    //   route: "/report/auditor-performance",
    //   SubHeaderOptions: {
    //     title: "Auditor Performance",
    //     breadcrumb: [
    //       { label: "Home", isActive: false, route: "/dashboard" },
    //       { label: "Report", isActive: true },
    //     ],
    //   },
    // },
    // {
    //   moduleId: 769, //Temp
    //   route: "/supervision-tools",
    //   SubHeaderOptions: {
    //     title: "Live Summary",
    //     breadcrumb: [
    //       { label: "Home", isActive: false, route: "/dashboard" },
    //       { label: "Supervision Tools", isActive: true },
    //     ],
    //   },
    // },
    // {
    //   moduleId: 779, //Temp
    //   route: "/supervision-tools/auditor-assignment",
    //   SubHeaderOptions: {
    //     title: "Auditor Assignment",
    //     breadcrumb: [
    //       { label: "Home", isActive: false, route: "/dashboard" },
    //       { label: "Supervision Tools", isActive: true },
    //     ],
    //   },
    // },
    // {
    //   moduleId: 789, //Temp
    //   route: "/supervision-tools/report-user-assignment",
    //   SubHeaderOptions: {
    //     title: "Report User Assignment",
    //     breadcrumb: [
    //       { label: "Home", isActive: false, route: "/dashboard" },
    //       { label: "Supervision Tools", isActive: true },
    //     ],
    //   },
    // },
  ]
}

export function getModuleNavConfigById(moduleId) {
  const data = generateNavConfig().find((value) => {
    return value.moduleId == moduleId;
  });
  return data;
}

export function getSubModuleNavConfigById(moduleId, subModuleId) {
  const data = generateNavConfig().find((value) => {
    return value.moduleId == moduleId;
  });
  if (data) {
    const subData = data.children.find((value) => {
      if (subModuleId == 105) {
        const user = JSON.parse(new EncryptedStorage().getItem(new GlobalConfig().userAllDetailsLSName));
        if (user.sysRoleId == 310) {
          return value.moduleId == subModuleId && value.route == "/dashboard/supervisor"
        } else {
          return value.moduleId == subModuleId && value.route == "/dashboard/client-admin"
        }
      } else {
        return value.moduleId == subModuleId;
      }
    });
    return subData;
  } else {
    return null;
  }
}

export function DynamicNavRouteConfig() {
  let navRouteConfig = _.cloneDeep(generateNavConfig());
  const systemModules: any[] = JSON.parse(new EncryptedStorage().getItem(new GlobalConfig().userModulesSubmodulesLSName));
  navRouteConfig = navRouteConfig.map(navModule => {
    if (navModule.moduleId && systemModules && systemModules.length > 0) {
      const userModule = systemModules.find(sysModule => sysModule.sysModuleId == navModule.moduleId);
      navModule.children = navModule.children.map(navSubModule => {
        if (userModule && userModule?.subModuleList && userModule?.subModuleList?.length > 0) {
          const userSubModule = userModule.subModuleList.find(sysSubModule => sysSubModule.sysSubModuleId == navSubModule.moduleId);
          if (userSubModule) {
            navSubModule.SubHeaderOptions.title = userSubModule.displayName
          }
        }
        return navSubModule;
      })
    }
    return navModule;
  });
  return systemModules;
}
