<!-- Icon without tooltip -->
<span
  (click)="clickIcon($event)"
  *ngIf="!enableTooltip"
  [ngClass]="{
    disabled: disabled,
    solid: state == 'solid',
    hover: state == 'hover',
    grayColor: color == 'gray',
    linkColor: color == 'link',
    themeColor: color == 'theme',
    themeLightColor: color == 'themeLightColor',
    whiteColor: color == 'white',
    extraLargeSize: size == 'extra-large',
    largeSize: size == 'large',
    mediumSize: size == 'medium',
    extraMediumSize: size == 'extra-medium',
    mediumSmallSize: size == 'medium-small',
    smallSize: size == 'small',
    extraSmallSize: size == 'extra-small'
  }"
  [style.color]="customColor"
  class="svg_icon rm-icon_{{ icon }}"
>
  <span class="path1"></span>
  <span class="path2"></span>
  <span class="path3"></span>
  <span class="path4"></span>
  <span class="path5"></span>
  <span class="path6"></span>
  <span class="path7"></span>
  <span class="path8"></span>
  <span class="path9"></span>
  <span class="path10"></span>
  <span class="path11"></span>
  <span class="path12"></span>
  <span class="path13"></span>
  <span class="path14"></span>
  <span class="path15"></span>
</span>

<!-- Icon with tooltip -->
<span
  (click)="clickIcon($event)"
  [tooltip]="
    templateType == 'description'
      ? descriptionTemplate
      : templateType == 'error'
      ? errorTemplate
      : templateType == 'blank'
      ? blankTemplate
      : tooltipContent
  "
  *ngIf="enableTooltip"
  [content-type]="contentType"
  [options]="tooltipOptions"
  [ngClass]="{
    disabled: disabled,
    solid: state == 'solid',
    hover: state == 'hover',
    grayColor: color == 'gray',
    linkColor: color == 'link',
    themeColor: color == 'theme',
    themeLightColor: color == 'themeLightColor',
    whiteColor: color == 'white',
    extraLargeSize: size == 'extra-large',
    largeSize: size == 'large',
    mediumSize: size == 'medium',
    extraMediumSize: size == 'extra-medium',
    smallSize: size == 'small',
    extraSmallSize: size == 'extra-small'
  }"
  [style.color]="customColor"
  class="svg_icon rm-icon_{{ icon }}"
>
  <span class="path1"></span>
  <span class="path2"></span>
  <span class="path3"></span>
  <span class="path4"></span>
  <span class="path5"></span>
  <span class="path6"></span>
  <span class="path7"></span>
  <span class="path8"></span>
  <span class="path9"></span>
  <span class="path10"></span>
  <span class="path11"></span>
  <span class="path12"></span>
  <span class="path13"></span>
  <span class="path14"></span>
  <span class="path15"></span>
</span>

<ng-template #descriptionTemplate>
  <div class="tooltip-description-template">
    <p>{{ tooltipTitle || "Description:" }}</p>
    <span [innerHTML]="tooltipContent" *ngIf="tooltipContent"></span>
    <span [innerHTML]="'No Description'" *ngIf="!tooltipContent"></span>
  </div>
</ng-template>
<ng-template #infoTemplate>
  <div class="tooltip-description-template">
    <p>Information:</p>
    <span [innerHTML]="tooltipContent"></span>
  </div>
</ng-template>
<ng-template #blankTemplate>
  <div class="tooltip-blank-template">
    <span [innerHTML]="tooltipContent"></span>
  </div>
</ng-template>
<ng-template #errorTemplate>
  <div class="tooltip-error-template">
    <p>Error:</p>
    <span [innerHTML]="tooltipContent"></span>
  </div>
</ng-template>
