import { Pipe, PipeTransform } from '@angular/core';
import { ISubHeaderConfig } from './rm-header.model';
import { EncryptedStorage } from '../../utils/encrypted-storage';
import { GlobalConfig } from 'src/app/configs/global-config';

@Pipe({
  name: 'subHeaderTitle'
})
export class SubHeaderTitlePipe implements PipeTransform {
  systemModules: any[] = JSON.parse(new EncryptedStorage().getItem(new GlobalConfig().userModulesSubmodulesLSName));

  transform(config: ISubHeaderConfig): string {
    if((this.systemModules && this.systemModules.length > 0) && config.labelIds && config.labelIds.length == 3) {
      const module = this.systemModules.find(m => m.sysModuleId == config.labelIds[0]);
      if(module) {
        const submodule = module.subModuleList.find(sm => sm.sysSubModuleId == config.labelIds[1]);
        if(submodule) {
          const labelAction = submodule.moduleActionList.find(action => action.sysActionId == config.labelIds[2]);
          if(labelAction && labelAction.displayName) {
            return labelAction.displayName;
          }
        }
      }
    }
    return config.title;
  }

}
