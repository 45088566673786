<div class="auth_layout container-fluid">
  <div class="row">
    <div class="col-md-7 order-md-2 login-layout">
      <div class="login-viev">
        <div class="LoginLogo"></div>
        <router-outlet></router-outlet>
        <ng-container *ngIf="loading">
          <div class="loader-body">
            <rm-loader></rm-loader>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-md-5 constant-view">
      <div class="bg_img"></div>
      <div class="info">
        <!-- <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin
                    gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum
                    sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                </p> -->
        <p>
          You have billing data, we have a platform to increase your toplines by
          tapping missing revenue opportunities using advanced machine learning
          techniques.<br />
          <br />
          RevenueMaximizer - a cloud-based SaaS platform that helps your
          organization to find anomalies and maximize revenue potentials. A
          feature rich responsive user interface for auditors to efficiently
          work on prioritized predictions and capture gaps in billing system
          early in the revenue cycle.
        </p>
        <h6 class="contact-support">
          Contact Support:<br />
          <!-- US: <a href="tel: +91 123 123 1234">+91 123 123 1234</a> &nbsp;|&nbsp; -->
          <a [href]="'mailto:'+contactSupportEmail">{{contactSupportEmail}}</a>
        </h6>
      </div>
    </div>
  </div>
</div>
