import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { ApiConfig } from "src/app/configs/api-config";
import { environment } from "./../../../environments/environment";
import { EncryptedStorage } from "../utils/encrypted-storage";
import { currentUser } from "../utils/current-user";
import { getAuthToken } from "../utils/auth-token";
import { map } from "rxjs/internal/operators/map";
import { GlobalConfig } from "src/app/configs/global-config";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
@Injectable()
export class LoginService {
  // Variables
  baseUrl: string;
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  constructor(private httpClient: HttpClient, private router: Router, private deviceService: DeviceDetectorService) {
    this.baseUrl = environment.apiUrl;
    this.userSubject = new BehaviorSubject<any>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): any {
    return this.userSubject.value;
  }

  oldLoginUser(userModel): Observable<any> {
    // Return Auth Token if success
    return this.httpClient.post(
      this.baseUrl + "/login-service/api/login",
      userModel, {responseType: "text"}
    );
    // Need to Fix (Refresh Token Logic)
    // .pipe(
    //   map((token) => {
    //     // this.userSubject.next(token);
    //     this.startRefreshTokenTimer();
    //     return token;
    //   })
    // );
  }

  loginUser(userModel): Observable<any> {
    // Return Auth Token if success
    userModel["deviceInfo"] = {
      deviceType: this.deviceService.getDeviceInfo().browser,
      deviceId: getUniqueId()
    }
    return this.httpClient.post(
      this.baseUrl + "/ak-login-service/api/login",
      userModel,
    );
  }

  getNavigationModulesAndSubmodules = () => {
    const userData = JSON.parse(new EncryptedStorage().getItem("_rm-sad"));
    const orgId = userData.organizationId;
    const roleId = userData.roleId;
    return this.httpClient.get(
      `${this.baseUrl}/subscription-service/sub/roleModuleMapping/${orgId}/${roleId}`
    );
  };

  getNavigationModulesAndSubmodulesByUserAuth = () => {
    return this.httpClient.get(
      `${this.baseUrl}/subscription-service/sub/roleModuleMapping/user`
    );
  };


  getNavigationBookmarks = () => {
    return this.httpClient.get(
      `${this.baseUrl}/subscription-service/sub/roleModuleMapping/getUserBookMark`
      // `${this.baseUrl}/subscription-service/sub/roleModuleMapping/getUserBookMark/${orgId}/${userId}`
    );
  };

  getAllOrgType(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/subscription-service/sub/sysOrgType`
    );
  }

  getAllUserListUsingOrgId(userId): Observable<any> {
    // return this.httpClient.get(
    //   `${this.baseUrl}/subscription-service/sub/user/${userId}/findAllUser`
    // );
    return this.httpClient.get(
      `${this.baseUrl}/subscription-service/sub/user/${userId}/findClientSupervisorAndAuditor`
    );
  }
  
  // Get Client Report Master
  getAllResponseCodes(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/client-admin-service/client/mstTypeLookup/RESPONSE`
    );
  }

  // Logout
  logoutUser(): Observable<any> {
    const payload = {
      deviceInfo: {
        deviceType: this.deviceService.getDeviceInfo().browser,
        deviceId: getUniqueId()
      }
    }
    return this.httpClient.put(`${this.baseUrl}/ak-login-service/api/logout`, payload);
  }

  generateNewAuthTokenUsingRefreshToken(baseUrl: string, token: string): Observable<any> {
    let payLoad = {
      refreshToken: token
    }
    return this.httpClient.post(`${baseUrl}/ak-login-service/api/refresh`,payLoad);
  }

  confirmUserToken(token: string) {
    return this.httpClient
      .get<any>(`${this.baseUrl}/subscription-service/auth/confirmUser?token=${token}`);
  }

  createNewPassword(payload: any) {
    return this.httpClient
      .post<any>(`${this.baseUrl}/subscription-service/auth/savePassword`, payload);
  }

  // Refresh Token
  // refreshToken() {
  //   return this.httpClient
  //     .get<any>(`${this.baseUrl}/login-service/api/tokenRefresh`);
  // }

  // refreshTokenTimeout: NodeJS.Timeout;

  // startTimer(time: number): Promise<Observable<any>> {
  //   return new Promise<Observable<any>>((resolve, reject) => {
  //     this.refreshTokenTimeout = setTimeout(() => {
  //     resolve(this.refreshToken());
  //     }, time);
  //   })
  // }

  // setupTimer(isRememberMe) {
  //   const jwtToken = JSON.parse(atob(new EncryptedStorage().getItem(new GlobalConfig().authTokenLSName).split(".")[1]));
  //   const expiresTimed = new Date(jwtToken.exp * 1000);
  //   // Set Expiry 2 minutes before token expiry
  //   const timeOut = new Date(expiresTimed.getTime() - (1000 * 120));
  //   new EncryptedStorage().setItem(
  //     new GlobalConfig().tokenTimeToReset,
  //     timeOut.getTime() - new Date().getTime(),!isRememberMe as boolean
  //   )
  // }

  // private stopRefreshTokenTimer() {
  //   clearTimeout(this.refreshTokenTimeout);
  // }
}

export function getUniqueId() {

  let value = window.sessionStorage.getItem('unique-id');
  console.log(value);

  if(!value || !window.name) {
      value = generateUuid();
      window.sessionStorage.setItem('unique-id', value);
  }

  window.name = value;
  return value;
}

export function generateUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random()*16|0, v = c === 'x' ? r : ((r&0x3)|0x8);
      return v.toString(16);
  });
}