<!-- <div > -->
<mat-card class="rm-notification-panel">
  <!-- Title -->
  <!-- <p class="panel-title">Notifications</p> -->

  <!-- Tabs -->
  <rm-tabs
    (configChange)="tabConfig = $event; tabChange($event)"
    [config]="tabConfig"
  ></rm-tabs>

  <!-- System -->
  <ng-template #system>
    <div class="actions">
      <a href="javascript:void(0);" *ngIf="systemNotifications && systemNotifications.length > 0" (click)="markAllAsRead('alert')">Mark All as Read</a>
    </div>
    <mat-action-list *ngIf="systemNotifications && systemNotifications.length > 0; else noNotificationTemplateRef">
      <ng-container *ngFor="let category of systemNotifications | notificationByDate: isNotificationMarkedAllAsRead">
        <!-- Category Separator -->
        <mat-subheader>{{ category.occuredDay }}</mat-subheader>

        <!-- Messages within this category -->
        <ng-container *ngFor="let message of category.notifications">
          <mat-list-item [ngClass]="{ 'unread': !message?.isRead }" (click)="markAsRead(message, 'alert')">
            <!-- <button mat-list-item> -->
              <rm-avatar matListItemIcon [firstName]="message?.firstName" [lastName]="message?.lastName"></rm-avatar>
              <span class="notification-item-title" matListItemTitle>
                {{ message?.msgBody }}</span>
              <span class="notification-item-time" matListItemLine>
                {{ message?.fromNow == 'Today' ? message?.fromNow : message?.rawTime }}
              </span>
              <span class="unread-indicator" *ngIf="!message?.isRead"></span>
            <!-- </button> -->
            <!-- <rm-avatar matListItemIcon [firstName]="message?.firstName" [lastName]="message?.lastName"></rm-avatar>
            <span class="notification-item-title" matListItemTitle>
              {{ message?.msgBody }}</span>
            <span class="notification-item-time" matListItemLine>
              {{ message?.fromNow == 'Today' ? message?.fromNow : message?.rawTime }}
            </span> -->
          </mat-list-item>
        </ng-container>
      </ng-container>
    </mat-action-list>
  </ng-template>

  <!-- Message Board -->
  <ng-template #messageBoard>
    <div class="actions">
      <a href="javascript:void(0);" *ngIf="messageNotifications && messageNotifications.length > 0" (click)="markAllAsRead('message')">Mark All as Read</a>
    </div>
    <mat-action-list *ngIf="messageNotifications && messageNotifications.length > 0; else noNotificationTemplateRef">
      <ng-container *ngFor="let category of messageNotifications | notificationByDate: isMessageMarkedAllAsRead">
        <!-- Category Separator -->
        <mat-subheader>{{ category.occuredDay }}</mat-subheader>

        <!-- Messages within this category -->
        <ng-container *ngFor="let message of category.notifications">
          <mat-list-item [ngClass]="{ 'unread': !message?.isRead }" (click)="markAsRead(message, 'message')">
            <rm-avatar matListItemIcon [firstName]="message?.firstName" [lastName]="message?.lastName"></rm-avatar>
            <span class="notification-item-title" matListItemTitle>
              {{ message?.subject | uppercase }}
            </span>
            <span class="notification-item-message" matListItemLine>
              {{ message?.msgBody | messageSummary }}
            </span>
            <span class="notification-item-time" matListItemLine>
              {{ message?.fromNow == 'Today' ? message?.fromNow : message?.rawTime }}
            </span>
            <span class="unread-indicator" *ngIf="!message?.isRead"></span>
          </mat-list-item>
        </ng-container>
      </ng-container>
    </mat-action-list>
  </ng-template>

  <ng-template #noNotificationTemplateRef>
    <div class="empty-notification">
      <div class="no-data">
        <img
          class="noDataImg"
          src="../../../../assets/img//Icon_NoData.png"
          alt="No New Notification"
        />
        <span>No New Notification</span>
      </div>
    </div>
  </ng-template>
</mat-card>
<!-- </div> -->
