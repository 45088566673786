import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NotificationAlertModel, NotificationMessageModel, NotificationStoreModel } from "./rm-notification-model";
import { MessageBoardService } from "../../_http/message-board.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "rm-notification-panel",
  templateUrl: "./rm-notification-panel.component.html",
  styleUrls: ["./rm-notification-panel.component.scss"],
  providers: [MessageBoardService]
})
export class RMNotificationPanelComponent implements AfterViewInit, OnChanges {
  // ViewChild
  @ViewChild("system") systemView: TemplateRef<any>;
  @ViewChild("messageBoard") messageView: TemplateRef<any>;

  @Input({alias: 'notifications'}) notificationsInput: NotificationStoreModel;
  @Output() onMarkAsRead: EventEmitter<any> = new EventEmitter();

  tabConfig: any;
  // systemNotifications: any[];
  systemNotifications: NotificationAlertModel[] = [];
  messageNotifications: NotificationMessageModel[] = [];

  systemCount: number;
  messageBoardCount: number;

  isNotificationMarkedAllAsRead: boolean = false;
  isMessageMarkedAllAsRead: boolean = false;

  constructor(
    private messageBoardService: MessageBoardService
  ) {
    this.tabConfig = {
      selectedTabIndex: 0,
      tabs: [
        {
          viewId: 1,
          label: "Alerts",
          value: "alert",
          template: null,
        },
        {
          viewId: 2,
          label: "Message Board",
          value: "messageBoard",
          template: null,
        },
      ],
    };
    this.systemNotifications = [];
    this.messageNotifications = [];
  }

  // Inside RMNotificationPanelComponent
  isToday(date: string): boolean {
    const createdAt = new Date(date);
    const today = new Date();

    return (
      createdAt.getDate() === today.getDate() &&
      createdAt.getMonth() === today.getMonth() &&
      createdAt.getFullYear() === today.getFullYear()
    );
  }

  formatDateTime(date: string): string {
    const createdAt = new Date(date);

    if (this.isToday(date)) {
      return createdAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return createdAt.toLocaleString([], { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['notificationsInput']) {
      this.systemNotifications = this.notificationsInput.alerts;
      this.messageNotifications = this.notificationsInput.messages;
      
      this.updateCount();
    }
  }

  ngAfterViewInit() {
    this.tabConfig.tabs.forEach((tab) => {
      switch (tab.viewId) {
        case 1:
          tab.template = this.systemView;
          break;
        case 2:
          tab.template = this.messageView;
          break;
        default:
          break;
      }
    });
  }

  updateCount() {
    this.systemCount = this.systemNotifications?.filter(item => !item.isRead)?.length || 0;
    this.messageBoardCount = this.messageNotifications?.filter(item => !item.isRead)?.length || 0;

    // Temp update length
    this.tabConfig.tabs[0].label = `Alerts ${this.systemCount ? `(${this.systemCount})` : ''}`;
    this.tabConfig.tabs[1].label = `Message Board ${this.messageBoardCount ? `(${this.messageBoardCount})` : ''}`;
  }

  // Tab Change
  tabChange(event: any) {
    // Refresh data based on select tab
    // this.selectedTabIndex = event.selectedTabIndex;
  }

  markAsRead(message: NotificationAlertModel | NotificationMessageModel, type: 'alert' | 'message') {
    const emitEvent = () => {
      const event = {
        type,
        method: 'single',
        message,
      }
      this.onMarkAsRead.emit(event);
      this.updateCount();
    }
    if(type == "alert") {
      const alertObj: NotificationAlertModel = message as NotificationAlertModel;
      this.messageBoardService.setSingleAlertAsRead(alertObj.alertId).subscribe(
        res => {
          this.systemNotifications = this.systemNotifications.map(item => {
            if(item.alertId == alertObj.alertId) {
              return {...item, isRead: true}
            }
            return item;
          });
          emitEvent();
        }
      );
    } else {
      const messageObj: NotificationMessageModel = message as NotificationMessageModel;
      if(messageObj.msgType == 0) {
        this.messageBoardService.updateIsReadConversation(messageObj.msgId, 1).subscribe(
          res => {
            this.messageNotifications = this.messageNotifications.map(item => {
              if(item.msgId == messageObj.msgId) {
                return {...item, isRead: true}
              }
              return item;
            });
            emitEvent();
          }
        );
      } else if(messageObj.msgType == 1) {
        this.messageBoardService.updateIsReadBroadcast(messageObj.msgId, 1).subscribe(
          res => {
            this.messageNotifications = this.messageNotifications.map(item => {
              if(item.msgId == messageObj.msgId) {
                return {...item, isRead: true}
              }
              return item;
            });
            emitEvent();
          }
        );
      }
    }
  }

  markAllAsRead(type: 'alert' | 'message') {
    const emitEvent = () => {
      this.updateCount();
      const event = {
        type,
        method: 'all',
        message: [],
      }
      this.onMarkAsRead.emit(event);
    }
    if(type == 'alert') {
      this.messageBoardService.setAllAlertAsRead().subscribe(
        res => {
          this.isNotificationMarkedAllAsRead = true;
          this.systemNotifications = this.systemNotifications.map(item => ({...item, isRead: true}));
          emitEvent();
        }
      );
    } else {
      const conversationAllReadAPI = this.messageBoardService.setAllConversationAsRead();
      const broadcastAllReadAPI = this.messageBoardService.setAllBroadcastAsRead();
      forkJoin([conversationAllReadAPI, broadcastAllReadAPI]).subscribe(
        res => {
          this.isMessageMarkedAllAsRead = true;
          this.messageNotifications = this.messageNotifications.map(item => ({...item, isRead: true}));
          emitEvent();
        }
      );
    }
  }
}
