import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { environment as env } from "src/environments/environment";

interface Config {
  apiUrl: string;
  MessageBoardAPI: string;
}
export interface IAppConfig {
  baseUrl: string;
  baseDMUrl: string;
  baseStandardUrl: string;
  load: () => Promise<void>;
}
@Injectable({
  providedIn: "root",
})
export class AppConfig implements IAppConfig {
  public baseUrl: string;
  public baseDMUrl: string;
  public baseStandardUrl: string;

  constructor(private readonly http: HttpClient) {}

  public load(): Promise<void> {
    return this.http
      .get<Config>("assets/config.json")
      .toPromise()
      .then((config) => {
        // API URL
        this.baseUrl = config.apiUrl;
        environment.apiUrl = config.apiUrl;
        env.apiUrl = config.apiUrl;

        // Socket URL
        environment.MessageBoardAPIUrl = config.MessageBoardAPI;
      });
  }
}
export function initConfig(config: AppConfig): () => Promise<void> {
  return () => config.load();
}
