<mat-drawer-container class="drawer-class {{ drawerSize }}" [hasBackdrop]="hasBackdrop">
        <mat-drawer class="drawer-panel" #drawer (openedChange)="onDrawerToggle($event)" [opened]="isActive" [disableClose]="escClose" [position]="isRightSide ? 'end' : 'start'" [mode]="drawerMode">
                <ng-container *ngIf="!useCustomTemplate; else customTemplate">
                        <div class="drawer-header">
                                <span>{{ drawerTitle }}</span>
                                <!-- <rm-icon *ngIf="showCloseButton" state="hover" icon="cross16"></rm-icon> -->
                        </div>
                        <ng-container *ngTemplateOutlet="drawerContainer"></ng-container>
                </ng-container>
                <ng-template #customTemplate>
                        <ng-container *ngTemplateOutlet="drawerContainer"></ng-container>
                </ng-template>
        </mat-drawer>
        <mat-drawer-content>
                <ng-content></ng-content>
        </mat-drawer-content>
</mat-drawer-container>