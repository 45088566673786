import { Pipe, PipeTransform } from '@angular/core';
import { NotificationAlertModel, NotificationAlertWithDatesModel, NotificationMessageModel, NotificationMessageWithDatesModel, NotificationsDateGroupType } from '../rm-notification-model';
import * as moment from "moment";
import { currentUser } from 'src/app/shared/utils/current-user';
import { auditorsList } from 'src/app/shared/utils/current-user-auditors';

@Pipe({
  name: 'notificationByDate'
})
export class NotificationByDatePipe implements PipeTransform {

  transform(value: NotificationAlertModel[] | NotificationMessageModel[], isMarkAllAsRead: boolean): NotificationsDateGroupType[] {
    let timeZoneOffset: string = currentUser()?.offset;
    let offsetByMinute;
    if(timeZoneOffset) {
      timeZoneOffset = timeZoneOffset.replace("GMT", "");
      let timeSplit: any[] = timeZoneOffset.split(":")
      timeSplit[0] = parseInt(timeSplit[0]) * 60;
      offsetByMinute = parseInt(timeSplit[0]) + parseInt(timeSplit[1]);
    }

    const users = auditorsList();

    const getUserValue = (userName: string, propertyKey) => {
      const user = users?.find(item => item.userName == userName);
      if(user) {
        return user[propertyKey];
      } else {
        const userItems = userName.split('.');
        if(userItems.length == 2) {
          if(propertyKey == 'firstName') return userItems[0];
          else if(propertyKey == 'lastName') return userItems[1];
          else return userName;
        } else {
          return userName;
        }
      }
    }
    
    const notifications = value.map((item: NotificationAlertModel | NotificationMessageModel) => {
      if(offsetByMinute) {
        item.createdDate = moment(item.createdDate).utcOffset(offsetByMinute).toDate();
      } else {
        item.createdDate = moment(item.createdDate).toDate();
      }
      return {
        ...item,
        firstName: item.firstName ? item.firstName : getUserValue(item.senderName, 'firstName'),
        lastName: item.lastName ? item.lastName : getUserValue(item.senderName, 'lastName'),
        isRead: isMarkAllAsRead ? true : item.isRead,
        occuredDay: moment(item.createdDate).calendar({
          sameDay: "[Today]",
          nextDay: "[Tomorrow]",
          nextWeek: "dddd",
          lastDay: "[Yesterday]",
          lastWeek: "[Last] dddd",
          sameElse: "DD/MM/YYYY",
        }),
        rawDate: moment(item.createdDate).format("DD/MM/YYYY"),
        rawTime: moment(item.createdDate).format("hh:mm:ss A"),
        fromNow: moment(item.createdDate).fromNow(),
      }
    });
    
    const groupedNotifications = notifications.reduce<{
      [key: string]: (NotificationAlertWithDatesModel | NotificationMessageWithDatesModel)[]
    }>((groups, notificationItem) => {
      const date = notificationItem.rawDate;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(notificationItem);
      return groups;
    }, {});
    const groupedArray: NotificationsDateGroupType[] = Object.keys(groupedNotifications).map((date) => {
      return {
        date,
        occuredDay: groupedNotifications[date][0].occuredDay,
        fromNow: groupedNotifications[date][0].fromNow,
        notifications: this.sortObjectsByDate(groupedNotifications[date], 'createdDate', 'desc'),
      };
    });
    return this.sortObjectsByDate(groupedArray, 'date', 'desc');
  }

  sortObjectsByDate(array: any[], sortKey: string, sortType?: 'asc' | 'desc') {
    if(sortType == 'desc') {
      return array.sort((a, b) => {
        return moment(b[sortKey]).diff(a[sortKey]);
      })
    }
    return array.sort((a, b) => {
      return moment(a[sortKey]).diff(b[sortKey]);
    })
  }

}
