import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../../shared-modules/material.module';
import { RMNotificationPanelComponent } from "./rm-notification-panel.component";
import { RMTabsModule } from "../rm-tabs/package.module";
import { NotificationByDatePipe } from './utils/notification-by-date.pipe';
import { RMAvatarModule } from "../rm-avatar/package.module";
import { NotificationMessageSummaryPipe } from "./utils/message-summary.pipe";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RMAvatarModule,
    RMTabsModule
  ],
  declarations: [RMNotificationPanelComponent, NotificationByDatePipe, NotificationMessageSummaryPipe],
  exports: [RMNotificationPanelComponent],
})

export class RMNotificationPanelModule { }