import * as moment from "moment";
import { currentUser } from "src/app/shared/utils/current-user";

export const dateFormatter = (params) => {
    let timeZoneOffset: string = currentUser()?.offset;
    // timeZoneOffset = timeZoneOffset.replace("GMT", "").replace(":", "");
    let offsetByMinute;
    if(timeZoneOffset) {
        timeZoneOffset = timeZoneOffset.replace("GMT", "");
        let timeSplit: any[] = timeZoneOffset.split(":")
        timeSplit[0] = parseInt(timeSplit[0]) * 60;
        offsetByMinute = parseInt(timeSplit[0]) + parseInt(timeSplit[1]);
    }
    if(offsetByMinute) {
        const date = moment(params.value).utcOffset(offsetByMinute).format('MM/DD/YYYY')
        return params.value ? (date === 'Invalid date' ? '-' : date) : "-";
    } else {
        const date = moment(params.value).format('MM/DD/YYYY')
        return params.value ? (date === 'Invalid date' ? '-' : date) : "-";
    }
    
    
}
export const dateTimeFormatter = (params) => {
    const date = moment(params.value).format('MM/DD/YYYY HH:mm')
    return params.value ? (date === 'Invalid date' ? '-' : date) : "-";

}
export const dateTimeFullFormatter = (params) => {
    let timeZoneOffset: string = currentUser()?.offset;
    // timeZoneOffset = timeZoneOffset.replace("GMT", "").replace(":", "");
    let offsetByMinute;
    if(timeZoneOffset) {
        timeZoneOffset = timeZoneOffset?.replace("GMT", "");
        let timeSplit: any[] = timeZoneOffset?.split(":")
        timeSplit[0] = parseInt(timeSplit[0]) * 60;
        offsetByMinute = parseInt(timeSplit[0]) + parseInt(timeSplit[1]);
    }
    if(params.value && params.value != "" && !params.value.includes("+")) params.value = `${params.value}.000+00:00` 
    if(offsetByMinute) {
        const date = moment(params.value).utcOffset(offsetByMinute).format('MM/DD/YYYY HH:mm:ss');
        return params.value ? (date === 'Invalid date' ? '-' : date) : "--:--";
    } else {
        const date = moment(params.value).format('MM/DD/YYYY HH:mm:ss');
        return params.value ? (date === 'Invalid date' ? '-' : date) : "--:--";
    }
}

export const dateTimeToUserTz = (date: string) => {
    let timeZoneOffset: string = currentUser()?.offset;
    // timeZoneOffset = timeZoneOffset.replace("GMT", "").replace(":", "");
    let offsetByMinute;
    if(timeZoneOffset) {
        timeZoneOffset = timeZoneOffset?.replace("GMT", "");
        let timeSplit: any[] = timeZoneOffset?.split(":")
        if(timeSplit.length > 0 && timeZoneOffset) {
            timeSplit[0] = parseInt(timeSplit[0]) * 60;
            offsetByMinute = parseInt(timeSplit[0]) + parseInt(timeSplit[1]);
        } else {
            offsetByMinute = 0
        }
    }
    if(date && date != "" && !date.includes("+")) date = `${date}.000+00:00` 
    if(offsetByMinute != undefined) {
        const dateVal = moment(date).utcOffset(offsetByMinute).format('MM/DD/YYYY HH:mm:ss');
        return date ? (dateVal === 'Invalid date' ? '-' : dateVal) : "--:--";
    } else {
        const dateVal = moment(date).format('MM/DD/YYYY HH:mm:ss');
        return date ? (dateVal === 'Invalid date' ? '-' : dateVal) : "--:--";
    }
}

export const dateTimeToUserTzByFormat = (date: string, format: string) => {
    let timeZoneOffset: string = currentUser()?.offset;
    // timeZoneOffset = timeZoneOffset.replace("GMT", "").replace(":", "");
    let offsetByMinute;
    if(timeZoneOffset) {
        timeZoneOffset = timeZoneOffset?.replace("GMT", "");
        let timeSplit: any[] = timeZoneOffset?.split(":")
        if(timeSplit.length > 0 && timeZoneOffset) {
            timeSplit[0] = parseInt(timeSplit[0]) * 60;
            offsetByMinute = parseInt(timeSplit[0]) + parseInt(timeSplit[1]);
        } else {
            offsetByMinute = 0
        }
    }
    if(date && date != "" && !date.includes("+")) date = `${date}.000+00:00` 
    if(offsetByMinute != undefined) {
        const dateVal = moment(date).utcOffset(offsetByMinute).format(format);
        return date ? (dateVal === 'Invalid date' ? '-' : dateVal) : "--:--";
    } else {
        const dateVal = moment(date).format(format);
        return date ? (dateVal === 'Invalid date' ? '-' : dateVal) : "--:--";
    }
}