import { FeatureModuleTitleLabelingIds } from "src/app/configs/feature-module-actions";

export const MESSAGE_BOARD_KEYS = {
    SYS_MODULE_ID: FeatureModuleTitleLabelingIds.Client.ClientMessageBoard[0],
    SYS_SUB_MODULE_ID: FeatureModuleTitleLabelingIds.Client.ClientMessageBoard[1],
  };
export const MESSAGE_BOARD_SU_KEYS = {
    SYS_MODULE_ID: FeatureModuleTitleLabelingIds.Client.ClientMessageBoardSU[0],
    SYS_SUB_MODULE_ID: FeatureModuleTitleLabelingIds.Client.ClientMessageBoardSU[1],
  };
  
  export const MESSAGE_BOARD_ACTION_MAPPING: any = {
    add_message_save: {
      id: FeatureModuleTitleLabelingIds.Client.ClientMessageBoardNewMessage[2],
      action: "ADD_MESSAGE_SAVE",
    },
    start_conversation_save: {
      id: FeatureModuleTitleLabelingIds.Client.ClientMessageBoardNewConversation[2],
      action: "START_CONVERSATION_SAVE",
    },
    message_archive_move: {
      id: FeatureModuleTitleLabelingIds.Client.ClientMessageBoardMovetoArchive[2],
      action: "MESSAGE_ARCHIVE_MOVE",
    },
  };

  export const MESSAGE_BOARD_SU_ACTION_MAPPING: any = {
    add_message_save: {
      id: FeatureModuleTitleLabelingIds.Client.ClientMessageBoardNewMessage[2],
      action: "ADD_MESSAGE_SAVE",
    },
    start_conversation_save: {
      id: FeatureModuleTitleLabelingIds.Client.ClientMessageBoardNewMessage[2],
      action: "START_CONVERSATION_SAVE",
    },
    broadcast_message_save: {
      id: FeatureModuleTitleLabelingIds.Client.ClientMessageBoardNewBroadcastSU[2],
      action: "BROADCAST_MESSAGE_SAVE",
    },
    message_archive_move: {
      id: FeatureModuleTitleLabelingIds.Client.ClientMessageBoardMovetoArchiveSU[2],
      action: "MESSAGE_ARCHIVE_MOVE",
    }
  };
  