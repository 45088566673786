<button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
  &nbsp;
    <span aria-hidden="true">&times;</span>
  </button>
  <rm-icon style="margin-right: 10px" [size]="toastIcon == 'info' ? 'medium' : 'small'" [customColor]="toastIcon == 'info' ? '#0358a6' : ''" [icon]="toastIcon"></rm-icon>
  <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
    {{ title }} <ng-container *ngIf="duplicatesCount">({{ duplicatesCount + 1 }})</ng-container>
  </div>
  <div *ngIf="message && options.enableHtml" role="alertdialog" aria-live="polite"
    [class]="options.messageClass" class="innerHTML" [innerHTML]="message">
  </div>
  <div *ngIf="message && !options.enableHtml" role="alertdialog" aria-live="polite"
    [class]="options.messageClass" [attr.aria-label]="message">
    {{ message }}
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>